.download_app_banner_wrapper {
    position: fixed;
    z-index: 999999;
    background-color: #000;
    width: calc(100% - 30px);
    height: 65px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.download_app_banner_wrapper .icon_name_wrapper {
    display: flex;
    align-items: center;
}

.download_app_banner_wrapper .icon_name_wrapper .icon_wrapper {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%2305BD97FF' stroke-width='4' stroke-dasharray='8' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 10px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
}

.download_app_banner_wrapper .icon_name_wrapper .icon_wrapper .icon {
    background-repeat: no-repeat;
    background-size: contain;
    width: 25px;
    height: 25px;
    border-radius: 7px;
}

.download_app_banner_wrapper .icon_name_wrapper .shop_name {
    padding: 0 10px;
    font-family: "SF Pro Display";
    font-style: normal;
}

.download_app_banner_wrapper .icon_name_wrapper .shop_name > div > span {
    font-size: 14px;
    color: #fff;
    letter-spacing: 0.374px;
}

.download_app_banner_wrapper .icon_name_wrapper .shop_name > span {
    display: block;
    font-size: 12px;
    color: #8d8d8f;
    margin-top: 3px;
}

.download_app_banner_wrapper .download_btn {
    background-color: #0983fe;
    height: 35px;
    border-radius: 20px;
    padding: 0 15px;
    color: #fff;
    font-family: "SF Pro Display";
    font-style: normal;
    font-size: 14px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
}