.landingHeader {
    background: #F9F9F9;
    height: 100vh;
    overflow: hidden;
}

.termsContainer {
    background: #F9F9F9;
}

.termsWrapper {
    width:80%;
    margin: 0 auto;
    padding-top:32px;
}

.topLandingHeader {
    text-align: center;
    height: 90%;
}

.topLandingHeader .loading {
    font-weight: 500;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.market_wrapper {
    padding-bottom: 10px;
    margin-top: 30px;
    height: 100%;
}

.market_wrapper .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px 5px 0 0;
}

.market_wrapper .header .image_wrapper {
    margin-bottom: 10px;
    width: 69px;
    height: 69px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: inset 0px -5px 25px rgba(0, 0, 0, 0.35);
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 3px solid rgba(255, 255, 255, 0.7);
    background: radial-gradient(84.99% 101.03% at 15.01% -1.03%, #FFFFFF 0%, #76DBA5 16.73%, #05BD97 48.61%, #05BD97 57.48%, #29897B 80.21%);
    box-shadow: none;
}

.market_wrapper .header .image_wrapper > img {
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.market_wrapper .header .shop_name {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    color: #000000
}

.market_wrapper .qr_code_wrapper {
    border: 2px solid;
    border-color: #000000;
    border-radius: 5px;
    padding: 10px;
    width: max-content;
    margin: 10px auto 0;
}

.market_wrapper .text_style {
    font-family: "SF Pro Text";
    font-style: normal;
    font-size: 16px;
    padding: 0 10px;
}

.landingHeader h3 {
    margin-bottom:0px;
    margin-top:0px;
}

.landingHeader p {
    margin-top:0px;
}

.landingHeader a {
    color:#09b492;
    text-decoration: none;
}

.hm_l {
    padding-top:28px;
    width: 80px;
}

.footerLanding {
    position: relative;
    text-align: center;
}

.footerLanding a {
    color: #A87AB5;
}

.termsLastSection {
    padding-bottom:48px;
}

.feather_logo_wrapper a {
    color: #A87AB5;
}