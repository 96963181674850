.empty_cart_container {
    /* width: calc(100% - 18px); */
    /* height: calc(100vh - 350px); */
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 30px; */
}

.empty_cart_container .image_wrapper {
    width: 220px;
    height: 227px;
    position: relative;
}

.empty_cart_container .image_wrapper .bg_image {
    background-image: url("../../../images/bg_icon.svg");
    background-repeat: no-repeat;
    height: 227px;
}

.empty_cart_container.custom_theme .image_wrapper .bg_image {
    mask-image: url("../../../images/bg_icon.svg");
    mask-repeat: no-repeat;
    background: #05BD97;
}

.empty_cart_container .image_wrapper .stroller_supermarket {
    position: absolute;
    top: 36px;
    left: 43px;
}

.empty_cart_container .text_wrapper {
    margin-top: 20px;
    max-width: 200px;
}

.empty_cart_container .text_wrapper > h1 {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.374px;
    color: #8E8E93;
    margin-bottom: 5px;
}

.empty_cart_container .text_wrapper .subtitle {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    /* line-height: 24px; */
    text-align: center;
    letter-spacing: 0.374px;
    color: #8E8E93;
}