.orderCardHeader {
    position: fixed;
    /* left: 0;
    right: 0;
    top: 0; */
    width: calc(100% - 20px);
    z-index: 9;
    max-height: 47px;
    height: 47px;
    padding: 10px 13px 10px 9px;
    background: rgba(249, 249, 249, 0.7);
    box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(70px);
    color: #05BD97;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    /* line-height: 22px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.orderPageTitle {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    text-align: center;
}

.orderContainer {
    padding: 67px 20px 10px;
   }

.orderContainer .orderDetails {
    font-weight: 500;
    background: #cbcbce;
    padding: 15px 20px;
    margin: 0 -20px 20px;
}

.orderContainer .orderTitle,
.orderContainer .orderStatus {
    font-weight: 500;
}

.orderContainer .ordertotal .ordertotalText {
    margin-bottom:0px;
    font-size: 14px;
    color: #8b8b8b;
    font-weight: 500;
}