.productBodyContainer {
    padding: 0 0 90px;
}

.productBodyContainer .product-header {
    position: fixed;
    /* left: 0;
    right: 0;
    top: 0; */
    width: calc(100% - 20px);
    z-index: 9;
    max-height: 47px;
    height: 47px;
    padding: 10px;
    background: rgba(249, 249, 249, 0.7);
    box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(70px);
    color: #05BD97;
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .productBodyContainer .product-header {
      background: rgba(249, 249, 249, 0.95);
    }
}

.productBodyContainer .product-header .go_back {
    text-decoration: none;
    color: #05BD97;
    display: flex;
    align-items: center;
}

.productBodyContainer .product-header .go_back > img {
    padding-right: 5px;
}

.productBodyContainer .product-header .cart_wrapper {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #05BD97;
}

.productBodyContainer .product-header .cart_wrapper > svg {
    padding-right: 5px;
}

.productBodyContainer .productContainer {
    padding-top: 100px;
}

.productBodyContainer .productContainer .productImage {
    text-align: center;
    position: relative;
}

.productBodyContainer .productContainer .productImage > img {
    max-width: 100%;
    max-height: 250px;
}

.productBodyContainer .productContainer .productImage .on_sale_to {
    mask-image: url("../../../images/on-sale-bg.svg");
    position: absolute;
    left: 0;
    bottom: 0;
    mask-repeat: no-repeat;
    padding: 20px 75px 20px 17px;
    mask-position: center left;
    mask-size: 100%;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
    color: #FFFFFF;
    background-color: #05BD97;
}

.productBodyContainer .productContainer .productImage .on_sale_to .date {
    font-size: 19px;
}

.productBodyContainer .productContainer .singleProductPrice {
    position: relative;
    min-height: 50px;
    margin-top: 15px;
}

.productBodyContainer .productContainer .singleProductPrice .product-price-wrapper {
    position: relative;
    font-family: "SF Pro";
    width: fit-content;
    min-height: 36px;
}


.productBodyContainer .productContainer .singleProductPrice .product-price-wrapper .discountPercentage {
    background-color: #FFE501;
    padding: 0 5px;
    font-family: SF Pro;
    font-style: normal;
    font-weight: 860;
    font-size: 33.92px;
    line-height: 50px;
    letter-spacing: 0.528px;
    color: #000000;
    width: 104px;
}

.productBodyContainer .productContainer .singleProductPrice .product-price-wrapper .productPrice {
    background-color: #05BD97;
    font-family: "SF Pro";
    font-style: normal;
    font-weight: 860;
    font-size: 42px;
    line-height: 50px;
    letter-spacing: 0.52px;
    display: grid;
    grid-template-columns: calc(100% - 15px) 15px;
    align-items: center;
    justify-content: center;
    text-align: right;
    color: #FFFFFF;
    padding: 0 5px;
    width: 104px;
    height: 50px;
}

.productBodyContainer .productContainer .singleProductPrice .product-price-wrapper .productPrice span {
    font-family: "SF Pro";
    font-style: normal;
    font-weight: 510;
    font-size: 10px;
    line-height: 28px;
    letter-spacing: 0.52px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #FFFFFF;
    transform: rotate(-90deg);
    height: 20px;
}

.productBodyContainer .productContainer .singleProductPrice .product-price-wrapper .comparePrice {
    background-color: #FEE600;
    align-items: center;
    padding: 2px 5px 4px;
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    right: -27px;
    bottom: 0px;
    width: 17px;
    height: 44px;
}

.productBodyContainer .productContainer .singleProductPrice .product-price-wrapper .comparePrice p {
    font-family: "SF Pro";
    font-style: normal;
    font-weight: 510;
    font-size: 15px;
    line-height: 28px;
    letter-spacing: 0.528px;
    color: #000000;
    text-decoration-line: line-through;
    padding: 0;
    margin: 0;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    height: 33px;
}

.productBodyContainer .productContainer .singleProductPrice .product-price-wrapper .comparePrice span {
    font-family: SF Pro;
    font-style: normal;
    font-weight: 510;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.528px;
    color: #000000;
}

.productBodyContainer .productContainer .singleProductTitle .title_wrapper .title {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    margin: 31px 0 0;
    padding: 0 15px 0;
    text-align: center;
}

.productBodyContainer .productContainer .singleProductTitle .title_wrapper .vendor {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 19px;
    letter-spacing: 0.374px;
    color: #8E8E93;
    text-align: center;
    margin: 0;
    padding: 8px 0 20px;
}

.productBodyContainer .productContainer .addToCartSingle .addToCartButtonSingle {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: #05BD97;
    border-radius: 10px;
    height: 48px;
    width: calc(100% - 30px);
    margin: 0 15px;
    border: none;
}

.productBodyContainer .productContainer .addToCartSingle .addToCartButtonSingle.added {
    background-color: #FFFFFF;
    border: 2.5px solid #05BD97;
    box-sizing: border-box;
    color: #05BD97;
}

.productBodyContainer .productContainer .addToCartSingle .addToCartButtonSingle:focus-visible {
    outline: none;
}

.productBodyContainer .productContainer .addToCartSingle .addToCartButtonSingle:disabled {
    opacity: 0.6;
}

.productBodyContainer .productContainer .on_sale_wrapper {
    display: flex;
    padding: 32px 17px 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.productBodyContainer .productContainer .on_sale_wrapper .on_sale_btn {
    background: #F2F2F7;
    border-radius: 5px;
    border: none;
    padding: 7px;
    flex: 1 0 100px;
    margin: 5px;
}

.productBodyContainer .productContainer .product_variations {
    padding: 0 16px;
}

.productBodyContainer .productContainer .product_variations .variation_size .title,
.productBodyContainer .productContainer .product_variations .variation_color .title {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #000000;
    padding: 32px 17px 0;
}

.productBodyContainer .productContainer .product_variations .variation_size::after,
.productBodyContainer .productContainer .product_variations .variation_color::after {
    content: "";
    position: absolute;
    left: 17px;
    width: calc(100% - 34px);
    height: 0.5px;
    background-color: rgba(0, 0, 0, 0.15);
}

.productBodyContainer .productContainer .product_variations .variation_size .size_wrapper {
    display: flex;
    flex-direction: row;
    margin: 17px 17px 32px;
    overflow-x: auto;
}

.productBodyContainer .productContainer .product_variations .variation_size .size_wrapper .size {
    min-width: 43px;
    max-width: 43px;
    min-height: 43px;
    max-height: 43px;
    background: #F2F2F7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.productBodyContainer .productContainer .product_variations .variation_size .size_wrapper .size:not(:last-child) {
    margin-right: 16px;
}

.productBodyContainer .productContainer .product_variations .variation_color .color_wrapper {
    margin: 17px 17px 32px;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
}

.productBodyContainer .productContainer .product_variations .variation_color .color_wrapper::-webkit-scrollbar,
.productBodyContainer .productContainer .product_variations .variation_size .size_wrapper::-webkit-scrollbar {
    display: none;
}

.productBodyContainer .productContainer .product_variations .variation_color .color_wrapper,
.productBodyContainer .productContainer .product_variations .variation_size .size_wrapper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.productBodyContainer .productContainer .product_variations .variation_color .color_wrapper .wrapper {
    width: 71px;
    min-width: 71px;
    height: 80px;
    background: #F2F2F7;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
}

.productBodyContainer .productContainer .product_variations .variation_color .color_wrapper .wrapper:not(:last-of-type) {
    margin-right: 17px;
 }

.productBodyContainer .productContainer .product_variations .variation_color .color_wrapper .wrapper.selected,
.productBodyContainer .productContainer .product_variations .variation_size .size_wrapper .size.selected {
    border: 1.5px solid #05BD97;
    box-sizing: border-box;
 }

 .productBodyContainer .productContainer .product_variations .variation_color .color_wrapper .wrapper:disabled {
    opacity: 0.6;
 }

.productBodyContainer .productContainer .product_variations .variation_color .color_wrapper .wrapper .color {
    width: 30px;
    height: 30px;
    border-radius: 15px;
}

.productBodyContainer .productContainer .product_variations .variation_color .color_wrapper .wrapper .text {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.374px;
    color: #000000;
}

.productBodyContainer .productContainer .about_this_product {
    padding: 10px 15px 0;
}

.productBodyContainer .productContainer .about_this_product > h2 {
    margin: 0;
}

.productBodyContainer .recomendedProductsContainer h3 {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 25px;
    display: flex;
    color: #000000;
    padding: 0 15px;
    margin: 0;
    padding-top: 30px;
}

.productBodyContainer .recomendedProductsContainer .recomendedProducts {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 19px 19px 48px 0;
}

.productBodyContainer .recomendedProductsContainer .recomendedProducts::-webkit-scrollbar {
    display: none;
}

.productBodyContainer .recomendedProductsContainer .recomendedProducts {
    padding-bottom:40px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.productBodyContainer .recomendedProductsContainer .recomendedProducts .productBox {
    position: relative;
    display: inline-grid;
    margin-left: 19px;
    background: #FFFFFF;
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    grid-template-columns: 200px;
    height: 280px;
}
.productBodyContainer .recomendedProducts .body_container {
    padding: 18px 8px;
}

.productBodyContainer .recomendedProducts .body_container .productLink {
    outline: none;
    text-decoration: none;
}

.productBodyContainer .recomendedProducts .body_container .productCategoryTitle {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: 0.374px;
    color: #000000;
    min-height: 42px;
    max-height: 42px;
    overflow: hidden;
}

.productBodyContainer .recomendedProducts .body_container .productImg {
    min-height: 120px;
    max-width: 150px;
    max-height: 120px;
    padding: 0 24px;
    margin: 9px auto 0;
    text-align: center;
    position: relative;
}

.productBodyContainer .recomendedProducts .body_container .productImg > img {
    max-width: 100%;
    max-height: 120px;
}

.productBodyContainer .recomendedProducts .body_container .productImg .on_sale_to {
    mask-image: url("../../../images/on-sale-bg.svg");
    position: absolute;
    left: -8px;
    bottom: -10px;
    mask-repeat: no-repeat;
    padding: 11px 50px 14px 10px;
    mask-position: center left;
    mask-size: 100%;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 16px;
    color: #FFFFFF;
    background-color: #05BD97;
}

.productBodyContainer .recomendedProducts .body_container .productImg .on_sale_to .date {
    font-size: 11px;
}

.productBodyContainer .recomendedProducts .productBox .productPriceContainer {
    position: relative;
    min-height: 30px;
}


.productBodyContainer .recomendedProducts .productBox .productPriceContainer .vendor-price-wrapper {
    position: relative;
    max-width: calc(100% - 75px);
    font-family: SF Pro;
    width: fit-content;
    min-height: 30px;
    margin-bottom: 17px;
}

.productBodyContainer .recomendedProducts .productBox .productPriceContainer .vendor-price-wrapper .discountPercentage {
    background-color: #FFE501;
    padding: 0 5px;
    font-family: "SF Pro";
    font-style: normal;
    font-weight: 860;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.374px;
    color: #000000;
    width: 45px
}

.productBodyContainer .recomendedProducts .productBox .productPriceContainer .vendor-price-wrapper .productPrice {
    background-color: #05BD97;
    font-family: "SF Pro";
    font-style: normal;
    font-weight: 860;
    font-size: 20px;
    line-height: 30px;
    display: grid;
    grid-template-columns: calc(100% - 12px) 12px;
    align-items: center;
    justify-content: center;
    text-align: right;
    letter-spacing: 0.374px;
    color: #FFFFFF;
    padding: 0 5px;
    width: 45px;
}

.productBodyContainer .recomendedProducts .productBox .productPriceContainer .vendor-price-wrapper .productPrice > p {
    margin: 0;
}

.productBodyContainer .recomendedProducts .productBox .productPriceContainer .vendor-price-wrapper .productPrice span {
    font-family: "SF Pro";
    font-style: normal;
    font-weight: 510;
    font-size: 7px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.374px;
    color: #FFFFFF;
    transform: rotate(-90deg);
    height: 15px;
}

.productBodyContainer .recomendedProducts .productBox .productPriceContainer .vendor-price-wrapper .comparePrice {
    background-color: #FEE600;
    align-items: center;
    padding: 2px 5px 2px;
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    right: -20px;
    bottom: 0px;
    width: 10px;
    height: 26px;
}

.productBodyContainer .recomendedProducts .productBox .productPriceContainer .vendor-price-wrapper .comparePrice p {
    font-family: "SF Pro";
    font-style: normal;
    font-weight: 510;
    font-size: 9px;
    line-height: 20px;
    letter-spacing: 0.374px;
    color: #000000;
    text-decoration-line: line-through;
    padding: 0;
    margin: 0;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    height: 19px;
}

.productBodyContainer .recomendedProducts .productBox .productPriceContainer .vendor-price-wrapper .comparePrice span {
    font-family: "SF Pro";
    font-style: normal;
    font-weight: 510;
    font-size: 6px;
    line-height: 8px;
    letter-spacing: 0.374px;
    color: #000000;
 }

.productBodyContainer .recomendedProducts .productBox .productPriceContainer .productCartButton {
    width: 40px;
    height: 40px;
    box-shadow: -4px -4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 16px 0px 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
}

.productBodyContainer .recomendedProducts .productBox .productPriceContainer .productCartButton.added {
    background: #05BD97;
}

.productBodyContainer .recomendedProducts .productBox .productPriceContainer .productCartButton .addButton {
    border-radius: 16px 0px 10px;
    border: none;
    background: transparent;
}

.productBodyContainer .recomendedProducts .productBox .productPriceContainer .productCartButton .addButton > .plus_icon {
    mask-image: url("../../../catalog/top_offers/assets/img/plus.svg");
    mask-repeat: no-repeat;
    mask-size: 28px;
    width: 28px;
    height: 28px;
    background: #05BD97;
}

.productBodyContainer .recomendedProducts .productBox .productPriceContainer .productCartButton.added .addButton > .checkmark_icon {
    mask-image: url("../../../searchPage/assest/img/check_mark_icon.svg");
    mask-repeat: no-repeat;
    mask-size: 20px;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
}


/* Skeleton style */
.single_product_image_skeleton > span {
    height: 200px;
}

.single_product_price_skeleton,
.single_product_price_skeleton > span {
    width: 250px;
    min-width: 50%;
    max-width: 50%;
}

.title_skeleton_wrapper {
    width: 50%;
}

.single_product_cart_btn_skeleton {
    margin: 47px 15px 0;
    max-width: calc(100% - 30px);
}

.single_product_cart_btn_skeleton > span > span {
    height: 48px;
}

.product_image_skeleton {
    display: flex;
    justify-content: center;
}

.product_image_skeleton > span {
    height: 120px
}

.product_title_skeleton > span {
    max-width: 60%;
}

.product_price_skeleton {
    max-width: 50%;
    width: 500px;
    position: relative;
    top: 50px;
}

.product_price_skeleton > span {
    width: 500px;
    max-width: 50%;
}

.product-toCart-skeleton-wrapper {
    width: 20%;
    float: right;
}