.no_promotions_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 165px 0 40px;
    grid-column-end: 3;
    grid-column-start: 1;
    height: calc(100vh - 400px);
    min-height: 320px;
}

.no_promotions_container .image_wrapper {
    width: 220px;
    height: 227px;
    position: relative;
}

/* .no_promotions_container .image_wrapper .bg_image {
    background-image: url("../../../images/bg_icon.svg");
    background-repeat: no-repeat;
    height: 227px;
}

.no_promotions_container.custom_theme .image_wrapper .bg_image {
    mask-image: url("../../../images/bg_icon.svg");
    mask-repeat: no-repeat;
    background: #05BD97;
    transform: rotate(-120deg);
} */

.no_promotions_container .image_wrapper .promotion_img {
    position: absolute;
    top: 105px;
    left: 40px;
}

.no_promotions_container .message_container {
    margin-top: 30px;
    max-width: 316px;
}

.no_promotions_container .message_container > h1 {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.374px;
    color: #8E8E93;
    margin: 0;
}

.no_promotions_container .message_container .message {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.374px;
    color: #8E8E93;
    padding-top: 14px;
}