.order_archive_container > .header {
    position: fixed;
    /* left: 0;
    right: 0;
    top: 0; */
    width: calc(100% - 20px);
    z-index: 9;
    max-height: 47px;
    height: 47px;
    padding: 10px;
    background: rgba(249, 249, 249, 0.7);
    box-shadow: 0px 0.5px 0px rgb(0 0 0 / 30%);
    -webkit-backdrop-filter: blur(70px);
    backdrop-filter: blur(70px);
    color: #05BD97;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.order_archive_container > .header .header_title {
    width: 100%;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.374px;
    color: #000000;
    margin: 0;
    justify-content: center;
    margin-left: 10px;
}

.order_archive_container > .body_container {
    padding-top: 69px;
}

.order_archive_container > .body_container .devider {
    width: 100%;
    height: 6px;
    background: #F5F6F7;
}

.order_archive_container > .body_container .archive_container {
    padding: 16px 17px 0 16px;
}

.order_archive_container > .body_container .archive_container .status,
.order_archive_container > .body_container .archive_container .items,
.order_archive_container > .body_container .archive_container .saved,
.order_archive_container > .body_container .archive_container .total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #8E8E93;
}

.order_archive_container > .body_container .archive_container .status span,
.order_archive_container > .body_container .archive_container .items .number {
    color: #000000;
    font-weight: 600;
}

.order_archive_container > .body_container .archive_container .status .icon {
    width: 5px;
    height: 10px;
    -webkit-mask-image: url("../images/angle-right-icon.svg");
    mask-image: url("../images/angle-right-icon.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    background: #05BD97;
}

.order_archive_container > .body_container .archive_container .saved,
.order_archive_container > .body_container .archive_container .total .number {
    color: #05BD97;
}

.order_archive_container > .body_container .archive_container .total {
    background: #F2F2F7;
    border-radius: 7px;
    padding: 8px 10px 8px 14px;
}