#giveaway_modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

#giveaway_modal_overlay .modal_wrapper {
    background: #FFFFFF;
    border-radius: 24px;
    width: 375px;
    max-width: calc(100% - 62px);
    max-height: calc(100vh - 75px);
    padding: 16px;
    margin-top: 80px;
}

#giveaway_modal_overlay .modal_wrapper.error {
    margin-top: 40px;
}

#giveaway_modal_overlay .modal_wrapper .header_wrapper {
    display: flex;
    position: relative;
    justify-content: center;
    height: 65px;
}

#giveaway_modal_overlay .modal_wrapper .header_wrapper .image {
    position: absolute;
    top: -93px;
}

#giveaway_modal_overlay .modal_wrapper .header_wrapper .image.error {
    top: -53px;
}

#giveaway_modal_overlay .modal_wrapper .header_wrapper .close_btn {
    position: absolute;
    right: 0px;
    top: 0;
    width: 30px;
    height: 30px;
    background: #F2F2F7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#giveaway_modal_overlay .modal_wrapper .body_wrapper {
    font-family: "SF Pro Display";
    font-style: normal;
    overflow-y: auto;
    max-height: calc(100vh - 220px);
}

#giveaway_modal_overlay .modal_wrapper .body_wrapper .body_title {
    font-weight: 700;
    font-size: 22px;
    letter-spacing: 0.374px;
    color: #05BD97;
    text-align: center;
    margin-top: 20px;
}

#giveaway_modal_overlay .modal_wrapper .body_wrapper .body_text {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.374px;
    color: #000000;
    margin-top: 20px;
}

#giveaway_modal_overlay .modal_wrapper .body_wrapper .content_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;
    margin-top: 40px;
}

#giveaway_modal_overlay .modal_wrapper .body_wrapper .content_wrapper .input_wrapper .input_container {
    display: flex;
    align-items: center;
}

#giveaway_modal_overlay .modal_wrapper .body_wrapper .content_wrapper .input_wrapper .input_container .prefix_number {
    width: 50px;
    background: #DFDFDF;
    border-radius: 8px 0 0 8px;
    display: flex;
    align-items: center;
    margin-right: -1px;
    padding: 14px 0 14px 10px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #8E8E93;
}

#giveaway_modal_overlay .modal_wrapper .body_wrapper .content_wrapper .input_wrapper .input_container > input {
    background: #DFDFDF;
    border-radius: 0 8px 8px 0;
    padding: 14px 10px 14px 0;
    border: none;
    outline: none;
    width: calc(100% - 70px);
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

#giveaway_modal_overlay .modal_wrapper .body_wrapper .content_wrapper .input_wrapper .input_container > input::placeholder {
    color: #8E8E93;
}

#giveaway_modal_overlay .modal_wrapper .body_wrapper .content_wrapper .input_wrapper .error_message {
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;
    letter-spacing: 0.374px;
    color: #FF3B30;
    margin-top: 6px;
}

#giveaway_modal_overlay .modal_wrapper .body_wrapper .content_wrapper .input_wrapper .info_message {
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;
    letter-spacing: 0.374px;
    color: #929292;
    margin-top: 7px;
}

#giveaway_modal_overlay .modal_wrapper .body_wrapper .content_wrapper .buttons_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#giveaway_modal_overlay .modal_wrapper .body_wrapper .content_wrapper .buttons_wrapper .save_btn {
    background: #05BD97;
    border-radius: 8px;
    width: 100%;
    height: 48px;
    border: none;
    color: #FFFFFF;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

#giveaway_modal_overlay .modal_wrapper .body_wrapper .content_wrapper .buttons_wrapper .save_btn:disabled {
    background: #8E8E93 !important;
}

#giveaway_modal_overlay .modal_wrapper .body_wrapper .content_wrapper .buttons_wrapper .dont_participate {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.374px;
    text-decoration-line: underline;
    text-underline-offset: 3px;
    color: #000000;
    margin-top: 15px;
    margin-bottom: 15px;
}

/* #region Success message style */
#giveaway_modal_overlay .modal_wrapper .body_wrapper .success_body_title {
    margin-top: 50px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    /* line-height: 22px; */
    text-align: center;
    letter-spacing: 0.374px;
}

#giveaway_modal_overlay .modal_wrapper .body_wrapper .success_body_text {
    margin: 60px 0;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    /* line-height: 19px; */
    letter-spacing: 0.374px;

    color: #000000;
}
/* #endregion Success message style */

/* #region Error message style */
#giveaway_modal_overlay .modal_wrapper .body_wrapper .error_body_title {
    margin-top: 30px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    /* line-height: 60px; */
    text-align: center;
    letter-spacing: 0.374px;
    color: #FF3B30;
}

#giveaway_modal_overlay .modal_wrapper .body_wrapper .error_body_text {
    margin-top: 20px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.374px;
    color: #000000;
}

#giveaway_modal_overlay .modal_wrapper .body_wrapper .try_again_btn {
    margin: 70px 0 10px;
    background: #FF3B30;
    border-radius: 8px;
    width: 100%;
    height: 48px;
    border: none;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* line-height: 16px; */
    text-align: center;
    color: #FFFFFF;
}
/* #endregion Error message style */