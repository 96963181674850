.shop_options_container {
    padding: 42px 24px;
}
.shop_options_container .shop_options_header {
    display: grid;
    grid-template-columns: 68px auto 92px;
    grid-column-gap: 10px;
    font-family: "SF Pro Text";
}

.shop_options_container .shop_options_header .shopping_cart_wrapper {
    background: #05BD97;
    border-radius: 14px;
    width: 69px;
    height: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shop_options_container .shop_options_header .items_value_container .title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    /* line-height: 60px; */
    letter-spacing: 0.374px;
    color: #000000;
    padding: 4px 0 3px;
}

.shop_options_container .shop_options_header .items_value_container {
    display: flex;
    flex-direction: column;
}

.shop_options_container .shop_options_header .items_value_container .regular_price {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    /* line-height: 60px; */
    letter-spacing: 0.374px;
    text-decoration-line: line-through;
    color: #8E8E93;
}

.shop_options_container .shop_options_header .items_value_container .current_price {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    /* line-height: 60px; */
    letter-spacing: 0.374px;
    color: #05BD97;
}

.shop_options_container .shop_options_header .items_on_cart {
    background: #FFFFFF;
    border: 1px solid #E7E7E6;
    box-sizing: border-box;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    width: 92px;
    height: 92px;
    justify-content: center;
}

.shop_options_container .shop_options_header .items_on_cart .number_of_products {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}

.shop_options_container .shop_options_header .items_on_cart .desc {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    color: #000000;
}

.shop_options_container .shop_options_body {
    padding-top: 15px;
}

.shop_options_container .shop_options_body .saved .title {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    /* line-height: 32px; */
    display: flex;
    align-items: center;
    color: #000000;
 }

.shop_options_container .shop_options_body .saved .value {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #000000;
}

.shop_options_container .shop_options_body .shop_options {
    display: flex;
    justify-content: center;
    flex-direction: row;
    column-gap: 15px;
    flex-wrap: wrap;
    row-gap: 15px;
    padding-top: 16px;
}

.shop_options_container .shop_options_body .shop_options.item_centered {
    grid-template-columns: 50%;
    justify-content: center;
}

.shop_options_container .shop_options_body .shop_options .shop_option_navigate,
.shop_options_container .shop_options_body .shop_options .shop_option_cart,
.shop_options_container .shop_options_body .shop_options .shop_option_apple_pay {
    padding: 27px 15px 10px;
    background: #FFFFFF;
    border: 1px solid #E7E7E6;
    box-sizing: border-box;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    min-width: 100px;
    max-width: 50%;
    flex: 1 1 30%;
}

.shop_options_container .shop_options_body .shop_options .shop_option_navigate .icon_wrapper,
.shop_options_container .shop_options_body .shop_options .shop_option_cart .icon_wrapper,
.shop_options_container .shop_options_body .shop_options .shop_option_apple_pay .icon_wrapper {
    background-color: rgba(5, 189, 151, 0.1);
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.shop_options_container .shop_options_body .shop_options .shop_option_navigate .icon_wrapper .icon {
    mask-image: url("./../img/navigate_icon_green.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    background: #05BD97;
    width: 48px;
    height: 48px;
}

.shop_options_container .shop_options_body .shop_options .shop_option_cart > .loaderContainer {
    width: 48px;
    height: 48px;
}

.shop_options_container .shop_options_body .shop_options .shop_option_cart > .loaderContainer > .loader {
    width: 10px;
    height: 10px;
    font-size: 7px;
    margin: 20px;
}

.shop_options_container .shop_options_body .shop_options .shop_option_cart .icon_wrapper .icon {
    mask-image: url("./../img/cart_icon_green.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    background: #05BD97;
    width: 48px;
    height: 48px;
}

.shop_options_container .shop_options_body .shop_options .shop_option_apple_pay .icon_wrapper .icon {
    mask-image: url("./../img/apple-pay-icon.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: calc(100% - 15px);
    background: #000000;
    width: 48px;
    height: 48px;
}

.shop_options_container .shop_options_body .shop_options .shop_option_navigate .title,
.shop_options_container .shop_options_body .shop_options .shop_option_cart .title,
.shop_options_container .shop_options_body .shop_options .shop_option_apple_pay .title {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: #000000;
    padding-top: 15px;
}

/* Chose locations style */
.locations_container .header {
    padding: 19px 16px 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.locations_container .header .title {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 34px;
    letter-spacing: 0.337647px;
    color: #000000;
}

.locations_container .header .close_btn {
    background-color: #F9F9F9;
    padding: 7px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    background-image: url("./../../../../images/close-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
}

.locations_container .body {
    padding-bottom: 15px;
    max-height: 70vh;
    overflow-y: auto;
}

.locations_container .body .location_wrapper {
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.locations_container .body .location_wrapper:not(:last-child) {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
}

.locations_container .body .location_wrapper .location_info .title {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #000000;
}

.locations_container .body .location_wrapper .location_info .coordinates {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    display: flex;
    align-items: center;
    color: #8E8E93;
    padding-top: 5px;
}

.locations_container .body .location_wrapper .chose_location_btn {
    background: rgba(5, 189, 151, 0.2);
    border-radius: 100px;
    padding: 6px 15px;

    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.408px;
    color: #05BD97;
}