.no_catalog_selected_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 320px);
    min-height: 400px;
}

.no_catalog_selected_container > img {
    padding: 0 45px;
    width: calc(100% - 90px);
}

.no_catalog_selected_container .title_wrapper {
    max-width: 314px;
    margin-top: 40px;
}

.no_catalog_selected_container .title_wrapper > h1 {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.374px;
    color: #8E8E93;
}

.no_catalog_selected_container .title_wrapper > div {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.374px;
    color: #8E8E93;
    margin-top: 14px;
}