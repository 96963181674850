@font-face {
  font-family: "SF Pro Display";
  src: url("./fonts/SF-Pro-Display-Regular.otf"),
       url("./fonts/SFProDisplay-Regular.ttf") format('truetype'),
       url("./fonts/SFProDisplay-Regular.woff") format('woff'),
       url("./fonts/SFProDisplay-Regular.woff2") format('woff2');
}

@font-face {
  font-family: "SF Pro Text";
  src: url("./fonts/SF-Pro-Text-Regular.otf"),
       url("./fonts/SFProText-Regular.ttf") format('truetype'),
       url("./fonts/SFProText-Regular.woff") format('woff'),
       url("./fonts/SFProText-Regular.woff2") format('woff2');
}

@font-face {
  font-family: "SF Pro";
  src: url("./fonts/SF-Pro-Display-Regular.otf"),
       url("./fonts/SFProDisplay-Regular.ttf") format('truetype'),
       url("./fonts/SFProDisplay-Regular.woff") format('woff'),
       url("./fonts/SFProDisplay-Regular.woff2") format('woff2');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.alertContainer{
  background-color: white;
  color:black;
  font-size:22px;
  margin-bottom:56px;
  height:28px;
  width:100%;
  padding:12px;
  text-align: center;
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 0px 0px 1px 5000px rgba(0,0,0,16%);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

#__react-alert__ > div {
  z-index: 999999 !important;
}

.custom_alert {
  padding: 15px;
  background: rgba(239, 239, 248, 0.95);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  top: 75px;
  max-width: calc(100% - 60px);
  margin: 0 auto;
}

.custom_alert > img {
  width: 34px;
  height: 34px;
  padding-right: 14px;
}

.custom_alert > div {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}

.general_msg_alert {
  padding: 14px;
  background: rgba(245, 245, 245, 0.6);
  backdrop-filter: blur(80px);
  border-radius: 23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  top: 41px;
  left: 0;
  right: 0;
  max-width: calc(100% - 60px);
  margin: 0 auto;
  pointer-events: all;
}

.general_msg_alert > img {
  width: 38px;
  height: 38px;
  margin: 9px 10px 9px 0;
  border-radius: 10px;
}

.general_msg_alert .content {
  width: 100%;
}

.general_msg_alert .content .close_icon {
  float: right;
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: -5px;
}

.general_msg_alert .content .close_icon > img {
  width: 14px;
  height: 14px;
}

.general_msg_alert .content .title {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  width: calc(100% - 30px);
}

.general_msg_alert .content .body {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.078px;
  color: #000000;
}