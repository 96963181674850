.product_not_found_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0 40px;
    grid-column-end: 3;
    grid-column-start: 1;
}

.product_not_found_container .image_wrapper {
    width: 220px;
    height: 227px;
    position: relative;
}

.product_not_found_container .image_wrapper .bg_image {
    background-image: url("../../../images/bg_icon.svg");
    background-repeat: no-repeat;
    height: 227px;
}

.product_not_found_container.custom_theme .image_wrapper .bg_image {
    mask-image: url("../../../images/bg_icon.svg");
    mask-repeat: no-repeat;
    background: #05BD97;
}

.product_not_found_container .image_wrapper .search_img {
    position: absolute;
    top: 0;
    left: 0;
}

.product_not_found_container .message_container {
    margin-top: 20px;
    max-width: 316px;
}

.product_not_found_container .message_container > h1 {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.374px;
    color: #8E8E93;
}

.product_not_found_container .message_container .message {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.374px;
    color: #8E8E93;
}