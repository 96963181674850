.contactInfoContainer{
    text-align: center;
    padding-bottom: 70px;

}
.infoLogoContainer {
    margin-top: 15px;
}

.infoLogoContainer .shop_image > img {
    max-width: 85px;
    width: 100%;
}

.infoLogoContainer .logoContainer {
    width: 85px;
    height: 85px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-image: url("../../../catalog/assets/img/logo_bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

.infoLogoContainer .logoContainer > img {
    width: 55px;
    height: 55px;
    transform: rotate(-1.94deg);
    padding-left: 5px;
}

.featherTitle {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.337px;
    color: #000000;
    margin: 0;
}

.featherSubTitle {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.07px;
    color: #999999;
    margin: 0;
}

.featherSubTitle a {
    color:#09b492;
    text-decoration: none;
}

.contactBoxes {
    padding-left:10px;
    padding-right:32px;
    margin-top:12px;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(4, 25%);
    grid-column-gap: 8px;
}

.contactBox {
    background: #FFFFFF;
    border: 0.5px solid #E5E5EA;
    border-radius: 10px;
}

.contactBox .contact_type_container {
    text-decoration: none;
    min-width: 72px;
    min-height: 57px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
}

.contactBox > .contact_type_container > div {
    margin-top: 13px;
    mask-size: 23px 18px;
    mask-repeat: no-repeat;
    width: 23px;
    height: 18px;
    background-color: #05BD97;
}

.contactBox > .contact_type_container > div.sms_icon {
    mask-image: url('../images/message_icon_green.svg');
}

.contactBox > .contact_type_container > div.email_icon {
    mask-image: url('../images/mail_icon_green.svg');
}

.contactBox > .contact_type_container > div.phone_icon {
    mask-image: url('../images/phone_icon_green.svg');
}

.contactBox > .contact_type_container > div.web_icon {
    mask-image: url('../images/web_icon_green.svg');
    height: 22px;
    mask-size: auto;
}

.contactBox > .contact_type_container > p {
    margin-bottom: 9px;
}

.boxText {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    letter-spacing: 0.07px;
    color: #05BD97;
    margin-top:0px;
}

.mapContainer {
    width:100%;
    height: 392px;
    margin-top:16px;
    margin-bottom:20px;
}

.mapContainer > div > div {
    border-radius: 14px;
}

.infoAddress {
    background: #FFFFFF;
    border: 1px solid #E5E5EA;
    box-sizing: border-box;
    border-radius: 14px;
    min-height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 16px;
    width: max-content;
    max-width: 100%;
    margin: 0 auto;
}

.locationPin {
    margin: 7px 9px 7px 0;
    mask-image: url('./../images/location_pin.svg');
    mask-size: 18px;
    mask-repeat: no-repeat;
    background-color: #05BD97;
    width: 18px;
    height: 18px;
}

.locationAddressText {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.24px;
    color: #000000;
    opacity: 0.6;
}

@media screen and (max-width: 279px) {
    .contactBoxes {
        grid-template-columns: repeat(2, 50%);
        grid-row-gap: 8px;
    }
}

@media screen and (max-width: 350px) {
    .contactBoxes {
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (min-width: 721px) {
    .contactBox a {
        flex-direction: row;
        justify-content: center;
    }
    
    .contactBox > a > img {
        margin-top: 0px;
        margin-right: 5px;
    }
    
    .contactBox > a > p {
        margin-bottom: 0px;
    }
}