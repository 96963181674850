.order_items_container > .header {
    position: fixed;
    /* left: 0;
    right: 0;
    top: 0; */
    width: calc(100% - 20px);
    z-index: 9;
    max-height: 47px;
    height: 47px;
    padding: 10px;
    background: rgba(249, 249, 249, 0.7);
    box-shadow: 0px 0.5px 0px rgb(0 0 0 / 30%);
    -webkit-backdrop-filter: blur(70px);
    backdrop-filter: blur(70px);
    color: #05BD97;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.order_items_container > .body_container {
    padding-top: 69px;
}

.order_items_container .body_container .items_container {
    padding: 18px 0 18px 16px;
}

.order_items_container .body_container .items_container .item_wrapper {
    display: flex;
    flex-direction: row;
}

.order_items_container .body_container .items_container .item_wrapper .item_image {
    width: 69px;
    height: 69px;
    background: #FFFFFF;
    border: 1px solid #E7E7E6;
    box-sizing: border-box;
    border-radius: 14px;
    position: relative;
    display: flex;
    align-items: center;
}

.order_items_container .body_container .items_container .item_wrapper .item_image > span {
    position: absolute;
    top: -5px;
    left: -5px;
    background: #05BD97;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    color: #FFFFFF;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.order_items_container .body_container .items_container .item_wrapper .item_image > img {
    max-width: 100%;
}

.order_items_container .body_container .items_container .item_wrapper .item_description {
    margin: 7px 0 0 10px;
    width: calc(100% - 79px);
    max-width: calc(100% - 79px);
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
    padding-right: 15px;
}

.order_items_container .body_container .items_container .item_wrapper .item_description > .title {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-top: 8px;
    color: #000000;
    min-height: 30px;
    max-height: 30px;
    max-width: 100%;
    overflow: hidden;
}

.order_items_container .body_container .items_container .item_wrapper .item_description .price_date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 8px;
}

.order_items_container .body_container .items_container .item_wrapper .item_description .price_date .price .regular {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    display: flex;
    align-items: center;
    text-decoration-line: line-through;
    color: #8E8E93;
}

.order_items_container .body_container .items_container .item_wrapper .item_description .price_date .price .current,
.order_items_container .body_container .items_container .item_wrapper .item_description .price_date .date_wrapper .date {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    display: flex;
    align-items: center;
}

.order_items_container .body_container .items_container .item_wrapper .item_description .price_date .price .current {
    color: #05BD97;
 }

.order_items_container .body_container .items_container .item_wrapper .item_description .price_date .date_wrapper {
    text-align: right;
}

.order_items_container .body_container .items_container .item_wrapper .item_description .price_date .date_wrapper .text {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    color: #8E8E93;
}

.body_container .total_container {
    background: #FFFFFF;
    padding: 18px 16px;
}

.body_container .total_container .discount,
.body_container .total_container .total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.body_container .total_container .discount {
    color: #05BD97;
}