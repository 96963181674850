#modal_with_bg_img {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
}

#modal_with_bg_img .modal_wrapper {
    position: relative;
    width: calc(100% - 20px);
    max-width: 342px;
    font-family: "SF Pro Display";
    font-style: normal;
    background-repeat: no-repeat;
    background-size: cover;
    height: 550px;
    background-position: center;
    border-radius: 24px;
    max-height: 100%;
}

#modal_with_bg_img .modal_wrapper .modal_header {
    margin: 24px 22px 0;
}

#modal_with_bg_img .modal_wrapper .modal_header .title {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 8px;
}

#modal_with_bg_img .modal_wrapper .modal_header .description {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #F0F0F0;
}

#modal_with_bg_img .modal_wrapper .modal_footer {
    position: absolute;
    bottom: 32px;
    width: 100%;
}

#modal_with_bg_img .modal_wrapper .modal_footer .button {
    margin: 0 26px;
    width: calc(100% - 52px);
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #00537B;
    background: #FFFFFF;
    border-radius: 6px;
}