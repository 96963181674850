.catalogContainer {
    padding: 0 0 100px;
}

.catalogContainer .catalogHeader {
    position: relative;
    width: 100%;
    height: 107px;
    background-image: url("./../img/header_bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 99999;
    display: flex;
    align-items: flex-end;
}

.catalogContainer .catalogHeader > .background {
    display: none;
}

.catalogContainer .catalogHeader.custom_theme.custom_bg > .background {
    -webkit-mask-image: url("./../img/header_bg.svg");
    mask-image: url("./../img/header_bg.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
}

.catalogContainer .catalogHeader .logoContainer {
    width: 69px;
    height: 69px;
    position: relative;
    bottom: -15px;
    left: 33px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: inset 0px -5px 25px rgba(0, 0, 0, 0.35);
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 3px solid rgba(255, 255, 255, 0.7);
    background: radial-gradient(84.99% 101.03% at 15.01% -1.03%, #FFFFFF 0%, #76DBA5 16.73%, #05BD97 48.61%, #05BD97 57.48%, #29897B 80.21%);
}

.catalogContainer .catalogHeader .logoContainer > img {
    width: 40px;
    height: auto;
}

.catalogContainer .catalogHeader.custom_theme .logoContainer > img {
    width: 100%;
    height: auto;
    border-radius: 50%;
}

.catalogContainer .catalogHeader.custom_theme .logoContainer > img.default_image {
    width: 45px;
    height: auto;
    position: relative;
    left: 3px;
    top: 3px;
    border-radius: unset;
}

.catalogContainer .catalogHeader.custom_theme.custom_bg .logoContainer {
    box-shadow: none;
}


.catalogContainer .catalogHeader:not(.custom_theme):not(.custom_bg) .logoContainer > img {
    width: 44px;
    transform: rotate(-1.94deg);
    padding: 3px 0 0 5px;
}

.catalogContainer .shop_title {
    position: absolute;
    margin-top: 25px;
    left: 22px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    color: #000000;
}

.catalogContainer .infoIconWrapper {
    background: #FFFFFF;
    position: absolute;
    right: 21px;
    margin-top: 25px;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0px 0px 10px #f2f2f7, 0px 0px 15px #f2f2f7, 0px 0px 20px #f2f2f7;
}

.catalogContainer .infoIconWrapper > div {
    text-decoration: none;
    display: flex;
    align-items: center;
}

.catalogContainer .infoIconWrapper > div > .info_icon {
    mask-image: url("../img/green_logo.svg");
    mask-repeat: no-repeat;
    mask-size: 17px;
    width: 17px;
    height: 17px;
    transform: rotate(-1.94deg);
    background: #05BD97;
}

.catalogContainer .infoIconWrapper.custom_theme > div > .info_icon {
    mask-image: url("../img/green_logo.svg");
    mask-repeat: no-repeat;
    mask-size: 17px;
}

.catalogContainer .shop_error_container {
    padding-top: 160px;
    padding-left: 15px;
    padding-right: 15px;
}

.catalogContainer .top_offers_categories_wrapper {
    padding-top: 50px;
}

.catalogContainer .topOffersContainer .react-horizontal-scrolling-menu--wrapper {
 position: relative;
}

.catalogContainer .topOffersContainer .react-horizontal-scrolling-menu--wrapper .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.catalogContainer .topOffersContainer .react-horizontal-scrolling-menu--wrapper .react-horizontal-scrolling-menu--scroll-container {
    padding-bottom:40px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.catalogContainer .topOffersContainer .react-horizontal-scrolling-menu--wrapper .react-horizontal-scrolling-menu--scroll-container .card:focus {
    outline: none !important;
}

@supports (scroll-snap-align: start) { 
    .catalogContainer .topOffersContainer .react-horizontal-scrolling-menu--wrapper .react-horizontal-scrolling-menu--scroll-container {
        /* scroll-snap-type: mandatory; */
        scroll-snap-type: x mandatory;
        -ms-scroll-snap-type: x mandatory;
    }
    .catalogContainer .topOffersContainer .react-horizontal-scrolling-menu--wrapper
    .react-horizontal-scrolling-menu--scroll-container > .react-horizontal-scrolling-menu--item {
        scroll-snap-align: center;
    }
}

.categoriesContainer {
    width: 100%;
    height: 100%;
}

.categoriesContainer .title_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px 16px;
}

.categoriesContainer .title_container .categoriesTitle {
    margin: 0;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
}

.categoriesContainer .title_container .all_categories {
    margin: 0;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    text-decoration: none;
    color: #05BD97;
    display: flex;
    align-items: flex-end;
}

.categoriesContainer .categoriesWrapper {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 8px));
    grid-column-gap: 14px;
    grid-row-gap: 14px;
    padding: 16px 10px 30px;
}

.categoriesContainer .categoriesWrapper.all_categories {
    grid-template-columns: 100%;
    padding: 0;
}

.categoriesContainer .categoriesWrapper .categories_loader {
    box-shadow: 4px 4px 42px rgb(0 0 0 / 8%);
    border-radius: 10px;
    background-image: url("../../../spinner/loader.gif");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    height: 50px;
    grid-column-end: 3;
    grid-column-start: 1;
}


/* Info modal style */

.slide-pane__overlay {
    z-index: 999999;
}

.info_modal_wrapper {
    width: calc(100% - 34px) !important;
    margin-top: 44px !important;
    height: calc(100vh - 44px) !important;
    padding: 17px;
    background: #F2F2F7 !important;
    backdrop-filter: blur(27.1828px);
    border-radius: 10px 10px 0px 0px;
}

.info_modal_wrapper .slide-pane__header {
    background: none;
    border: none;
    height: auto;
    display: block;
    flex: 0;
    width: fit-content;
}

.info_modal_wrapper .slide-pane__header .slide-pane__close {
    margin: 0 0 10px 0;
    padding: 0;
    opacity: 1;
    display: flex;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.41px;
    color: #05BD97;
}

.info_modal_wrapper .slide-pane__content {
    padding: 0;
    margin-bottom: 30px;
}

/* End Info modal style */


@media screen and (max-width: 359px) {
    .catalogContainer .catalogHeader .logoContainer {
        bottom: 0px;
    }
    .categoriesContainer .categoriesWrapper {
        grid-template-columns: 100%;
    }
    .categoriesContainer .categoriesWrapper.all_categories {
        padding: 0;
    }
}

@media screen and (min-width: 720px) {
    .categoriesContainer .categoriesWrapper {
        grid-template-columns: repeat(3, calc(33.333% - 8px));
    }
    .categoriesContainer .categoriesWrapper.all_categories {
        grid-template-columns: 100%;
        padding: 0;
    }
}

@media (width: 540px) and (height: 720px) and (orientation: portrait) {
    .catalogHeader {
        background-position-y: center;
    }
}

@media (width: 768px) and (height: 1024px) and (orientation: portrait) {
    .catalogHeader {
        background-position-y: calc(50% - 20px);
    }
    .logoContainer {
        bottom: 10px;
    }
}

@media (width: 820px) and (height: 1180px) and (orientation: portrait) {
    .catalogHeader {
        background-position-y: calc(50% - 20px);
    }
    .logoContainer {
        bottom: 10px;
    }
}

@media (orientation: landscape) {
    .catalogHeader {
        background-position: 0px calc(50% - 26px);
        align-items: center !important;
    }
    .logoContainer {
        bottom: unset;
    }
}


@media screen and (min-width: 911px) {
    .catalogHeader {
        background-position: 0 0;
        align-items: center;
    }
    .logoContainer {
        bottom: unset;
    }
    .infoIconWrapper {
        top: 120px;
    }
}