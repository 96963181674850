.categoryBox {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1), 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 20px 10px 34px;
}

.categoryBox .productLink {
    text-decoration: none;
}

.categoryBox .productLink .categoryTitle {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.374px;
    color: #000000;
    min-height: 47px;
    max-height: 47px;
    overflow: hidden;
}

.categoryBox .productLink .categoryImg {
    min-height: 137px;
    max-height: 137px;
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.categoryBox .productLink .categoryImg > img {
    max-width: 100%;
    max-height: 137px;
}


/* Skeleton style */
.title_skeleton > span {
    width: 70%;
}

.image_skeleton {
    width: 100%;
}

.image_skeleton > span {
    height: 145px !important;
}