.search_container {
    padding: 0 16px;
    min-width: calc(100vw - 32px);
    max-width: calc(100vw - 32px);
}

.search_container .no_promotions {
    margin-bottom: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.search_container .page_title {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: 0.374px;
    color: #000000;
    margin-bottom: 0;
    margin-bottom: 17px;
}

.search_container .search_info_msg_container {
    padding: 0px 0 100px;
}

.search_container .search_box_container {
    position: relative;
    display: flex;
    justify-content: space-between;
}

.search_container .search_box_container .search_box {
    background: rgba(118, 118, 128, 0.12);
    border-radius: 10px;
    width: calc(100% - 32px);
    padding: 7px 15px 7px 30px;
    border: none;
    color: #000000;
    height: 22px;
}

.search_container .search_box_container .search_box.searching {
    width: calc(100% - 110px);
}

.search_container .search_box_container .search_box:focus,
.search_container .search_box_container .search_box:focus-visible {
    outline: none;
}

.search_container .search_box_container .search_box::placeholder {
    color: rgba(0, 0, 0, 0.4);

}

.search_container .search_box_container .search_icon {
    position: absolute;
    left: 8px;
    top: 10px;
    color: #8E8E93;;
}

.search_container .search_box_container .cancel_btn {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    display: none;
    align-items: center;
    text-align: right;
    letter-spacing: -0.408px;
    color: #05BD97;
    padding-left: 5px;
}

.search_container .search_box_container .cancel_btn.searching {
    display: flex;
}

.search_container .discover_container .discover_title {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 25px;
    color: #000000;
    margin-top: 22px;
    margin-bottom: 10px;
}

.search_container .discover_container .category_list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.search_container .discover_container .category_list > li {
    padding: 10px 0;
}

.search_container .discover_container .category_list > li > a {
    text-decoration: none;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #05BD97;
}

.search_container .discover_container .category_list > li:first-of-type {
    border-top: 1px solid rgba(0, 0, 0, 0.25);
}

.search_container .discover_container .category_list > li:not(:last-of-type) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.search_container .suggestions_container {
    margin-bottom: 110px;
}

.search_container .suggestions_container .suggestions_title {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 25px;
    color: #000000;
    margin-top: 17px;
    padding: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.search_container .suggestions_container .products_container {
    margin-top: 12px;
}

.search_container .suggestions_container .products_container .product_container {
    display: flex;
    flex-direction: row;
    margin-right: -16px;
}

.search_container .suggestions_container .products_container .product_container .product_image {
    background: #FFFFFF;
    border: 1px solid #E7E7E6;
    box-sizing: border-box;
    border-radius: 14px;
    min-width: 69px;
    width: 69px;
    max-width: 69px;
    height: 69px;
    max-height: 69px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search_container .suggestions_container .products_container .product_container .product_image > img {
    max-width: 61px;
    max-height: 61px;
}

.search_container .suggestions_container .products_container .product_container .product_info_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    align-items: center;
    margin-left: 10px;
    padding-right: 26px;
    max-height: 64px;
    min-height: calc(64px + 11px);
}

.search_container .suggestions_container .products_container .product_container:not(:last-of-type) .product_info_container {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
}

.search_container .suggestions_container .products_container .product_container .product_info_container .product_title {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    /* line-height: 25px;  */
    color: #000000;
    margin-bottom: 5px;
    max-height: 14px;
    overflow: hidden;
    margin-right: 5px;
}

.search_container .suggestions_container .products_container .product_container .product_info_container .product_price_container .product_price {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    /* line-height: 25px; */
    text-decoration-line: line-through;
    color: #8E8E93;
    margin-bottom: 5px;
}

.search_container .suggestions_container .products_container .product_container .product_info_container .product_price_container .compare_price {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    /* line-height: 25px; */
    color: #05BD97;
}

.search_container .suggestions_container .products_container .product_container .product_info_container .add_to_cart_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search_container .suggestions_container .products_container .product_container .product_info_container .add_to_cart_container .add_to_cart_btn {
    background: #EEEEEE;
    border-radius: 14.5px;
    width: 69px;
    height: 24px;
}

.search_container .suggestions_container .products_container .product_container .product_info_container .add_to_cart_container .add_to_cart_btn .plus_icon {
    mask-image: url("./../img/plus_icon.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    height: 24px;
    background: #05BD97;
}

.search_container .suggestions_container .products_container .product_container .product_info_container .add_to_cart_container .add_to_cart_btn.loading {
    display: flex;
    align-items: center;
    justify-content: center;
}

.search_container .suggestions_container .products_container .product_container .product_info_container .add_to_cart_container .add_to_cart_btn.added {
    background-color: #05BD97;
}

.search_container .suggestions_container .products_container .product_container .product_info_container .add_to_cart_container .add_to_cart_btn.added .checkmark_icon {
    mask-image: url("./../img/check_mark_icon.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    height: 24px;
    background-color: #FFFFFF;
}

.search_container .suggestions_container .products_container .product_container .product_info_container .add_to_cart_container .btn_desc {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 8px;
    /* line-height: 25px; */
    text-align: center;
    color: #8E8E93;
    margin-top: 5px;
    white-space: nowrap;
}


/* Search products style */
.search_container .search_products_container {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 8px));
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    padding: 25px 16px 110px;
    margin: 0 -16px;
}

.search_container .search_products_container .search_products_loader {
    box-shadow: 4px 4px 42px rgb(0 0 0 / 8%);
    border-radius: 10px;
    background-image: url("../../../spinner/loader.gif");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    height: 50px;
    grid-column-end: 3;
    grid-column-start: 1;
}

.search_container .search_products_container .searchProductBox {
    background: #FFFFFF;
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
}

.search_container .search_products_container .searchProductBox .body_container {
    padding: 10px;
    text-decoration: none;
    position: relative;
}

.search_container .search_products_container .searchProductBox .body_container .productCategoryTitle {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.374px;
    color: #000000;
    height: 40px;
    max-height: 40px;
    overflow: hidden;
    padding: 0 10px;
}

.search_container .search_products_container .searchProductBox .body_container .categoryImg {
    min-height: 150px;
    max-width: 165px;
    max-height: 150px;
    padding: 0 5px;
    margin: 15px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search_container .search_products_container .searchProductBox .body_container .categoryImg > img {
    max-width: 100%;
    max-height: 150px;
}

.search_container .search_products_container .searchProductBox .body_container .categoryImg .on_sale_to {
    mask-image: url("../../../images/on-sale-bg.svg");
    position: absolute;
    left: 0px;
    bottom: 0;
    mask-repeat: no-repeat;
    padding: 25px 50px 30px 13px;
    mask-position: center left;
    mask-size: 100%;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 16px;
    color: #FFFFFF;
    background-color: #05BD97;
    width: max-content;
}

.search_container .search_products_container .searchProductBox .body_container .categoryImg .on_sale_to .date {
    font-size: 11px;
}

.search_container .search_products_container .searchProductBox .categoryPriceContainer {
    position: relative;
    min-height: 36px;
}

.search_container .search_products_container .searchProductBox .categoryPriceContainer .vendor-price-wrapper {
    position: relative;
    max-width: calc(100% - 75px);
    font-family: "SF Pro";
    width: fit-content;
    min-height: 36px;
}

.search_container .search_products_container .searchProductBox .categoryPriceContainer .vendor-price-wrapper .discountPercentage {
    background-color: #FFE501;
    padding: 0 5px;
    font-family: "SF Pro";
    font-style: normal;
    font-weight: 860;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.33px;
    color: #000000;
    width: 40px;
}

.search_container .search_products_container .searchProductBox .categoryPriceContainer .vendor-price-wrapper .productPrice {
    background-color: #05BD97;
    font-family: "SF Pro";
    font-weight: 860;
    font-size: 17px;
    line-height: 36px;
    display: grid;
    grid-template-columns: calc(100% - 10px) 10px;
    align-items: center;
    text-align: right;
    letter-spacing: 0.33px;
    color: #FFFFFF;
    padding: 0 5px;
    border-bottom-left-radius: 10px;
    width: 40px;
}

.search_container .search_products_container .searchProductBox .categoryPriceContainer .vendor-price-wrapper .productPrice > p {
    margin: 0;
}

.search_container .search_products_container .searchProductBox .categoryPriceContainer .vendor-price-wrapper .productPrice span {
    font-family: "SF Pro";
    font-weight: 510;
    font-style: normal;
    font-size: 7px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.33px;
    color: #FFFFFF;
    transform: rotate(-90deg);
    height: 5px;
}

.search_container .search_products_container .searchProductBox .categoryPriceContainer .vendor-price-wrapper .comparePrice {
    background-color: #FEE600;
    align-items: center;
    padding: 2px 5px 2px 5px;
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    right: -20px;
    bottom: 0px;
    width: 10px;
    height: 32px;
}

.search_container .search_products_container .searchProductBox .categoryPriceContainer .vendor-price-wrapper .comparePrice p {
    font-family: "SF Pro";
    font-style: normal;
    font-weight: 510;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.33px;
    color: #000000;
    text-decoration-line: line-through;
    padding: 0;
    margin: 0;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    height: 22px;
}

.search_container .search_products_container .searchProductBox .categoryPriceContainer .vendor-price-wrapper .comparePrice span {
    font-family: "SF Pro";
    font-weight: 510;
    font-size: 7px;
    line-height: 11px;
    letter-spacing: 0.33px;
    color: #000000;
 }

.search_container .search_products_container .searchProductBox .categoryPriceContainer .categoryCartButton {
    width: 40px;
    height: 40px;
    box-shadow: -4px -4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 16px 0px 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
}

.search_container .search_products_container .searchProductBox .categoryPriceContainer .categoryCartButton.added {
    background-color: #05BD97;
}

.search_container .search_products_container .searchProductBox .categoryPriceContainer .categoryCartButton .addButton {
    border-radius: 16px 0px 10px;
    border: none;
    background: transparent;
}

.search_container .search_products_container .searchProductBox .categoryPriceContainer .categoryCartButton .addButton > .plus_icon {
    mask-image: url("../img/plus_icon.svg");
    mask-repeat: no-repeat;
    mask-size: 23px;
    background-color: #05BD97;
    height: 23px;
    width: 23px;
}

.search_container .search_products_container .searchProductBox .categoryPriceContainer .categoryCartButton.added .addButton > .checkmark_icon {
    mask-image: url("../img/check_mark_icon.svg");
    mask-repeat: no-repeat;
    mask-size: 20px;
    height: 20px;
    width: 20px;
    background-color: #FFFFFF;
}

@media screen and (max-width: 350px) {
    .search_container .search_products_container {
        grid-template-columns: calc(100% - 2px);
    }
}

@media screen and (min-width: 720px) {
    .search_container .search_products_container {
        grid-template-columns: repeat(3, calc(33.333% - 11px));
    }
}



/* Skeleton style */
.search_container .discover_container .discover_title_skeleton > span {
    width: 50%;
}

.search_container .discover_container .discover_item_skeleton > span {
    min-height: 25px;
    margin: 5px 0;
}

.search_container .suggestions_container .suggestions_title_skeleton > span {
    width: 50%;
}

.search_container .suggestions_container .products_container .suggested_item_image_skeleton {
    min-width: 51px;
    min-height: 51px;
}

.search_container .suggestions_container .products_container .suggested_item_image_skeleton > span {
    min-height: 45px;
}

.search_container .suggestions_container .products_container .suggested_item_title_skeleton > span {
    max-width: 90%;
}

.search_container .suggestions_container .products_container .suggested_item_price_skeleton > span {
    max-width: 30%;
}

.search_container .suggestions_container .products_container .add_to_cart_container .cart_btn_skeleton > span {
    height: 25px;
}