/* .checkout_container {
    padding-top: 67px;
} */

.checkout_container .checkoutHeader {
    position: fixed;
    /* left: 0;
    right: 0;
    top: 0; */
    width: calc(100% - 20px);
    z-index: 9;
    max-height: 47px;
    height: 47px;
    padding: 10px 13px 10px 9px;
    background: rgba(249, 249, 249, 0.7);
    box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(70px);
    color: #05BD97;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    /* line-height: 22px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.checkout_container .checkoutHeader .go_back {
    text-decoration: none;
    color: #05BD97;
    display: flex;
    align-items: center;
}

.checkout_container .checkoutHeader .go_back > img {
    padding-right: 5px;
}

.pageTitle {
    margin-left: 16px;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-top: 0;
    padding-top: 90px;
}

.checkoutFromContainer {
 padding: 0px 20px 10px;
}

.checkoutFromContainer .checkoutFormTitle {
    font-weight: 500;
    background: #cbcbce;
    padding: 10px 20px;
    margin: 0 -20px 20px;
}

.checkoutFromContainer .shippingFormContainer {
    margin: 10px 0;
}

.checkoutFromContainer form > label {
    display: block;
    padding-bottom: 10px;
}

.checkoutFromContainer form > label > span {
    float: right;
    color: red;
    font-size: 13px;
}

.checkoutFromContainer form > label > input {
    display: block;
    width: calc(100% - 26px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    -moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
}

.checkoutFromContainer form > label > input,
.checkoutFromContainer form > label > select {
    border: 1px solid silver;
    border-radius: 5px;
    outline: none;
    background-color: #fff;
    margin-top: 5px;
}

.checkoutFromContainer form > label > select {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    line-height: 1.5;
    background: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat;
    background-size: 12px;
    background-position: calc(100% - 10px) center;
    background-repeat: no-repeat;
    -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
}

.red_border {
    border-color: red !important;
}

.red_text {
    color: red !important;
}

.checkoutFromContainer .paymentGateways > div:last-of-type {
    display: flex;
    flex-direction: column;
}

.checkoutFromContainer .paymentGateways {
    margin: 10px 0;
}

.checkoutFromContainer .paymentGateways > div:last-of-type > div {
    padding: 5px 0;
}

.checkoutFromContainer .paymentGateways > div:last-of-type > div > input {
    margin-right: 7px;
}

.checkoutFromContainer .paymentGateways > div:last-of-type > div > p {
    margin: 5px 0 0 25px;
    font-size: 12px;
    background-color: rgba(192, 192, 192, 0.5);
    padding: 5px;
    color: #000000 !important;
}

.cartItemsContainer {
    width: 100%;
}

.cartItemsContainer .cartItemsTitle {
    font-weight: 500;
    background: #cbcbce;
    padding: 10px 20px;
    margin: 0 0 20px;
}

.itemsInfo h4 {
    display: inline-block;
}

.itemsInfo a {
    float: right;
    padding-top:22px;
    text-decoration: none;
    margin-right:16px;
}

.itemsInfo h4 {
    width:40%;
}

.cartItemsTable {
    width:100%;
    border:none;
}

.cartItemsHeader {
    background: #cbcbce;
    border: none;
}

.cartItemBodyLeft {
    text-align: center;
    width: 24%;
    padding: 10px;
}

.cartItemBodyLeft > img {
    max-height: 110px;
    width: auto;
    max-width: 100%;
}

.cartItemBodyMiddle {
    text-align:left;
    width: 50%;
}

.cartItemBodyMiddle > p:last-of-type {
    margin-bottom: 24px;
    font-size: 14px;
}

.cartItemBodyRight {
    text-align: right;
    font-weight: 600;
    vertical-align: bottom;
    padding-bottom:24px;
    padding-right:15px;
    width: 24%;
}

.cartItemBodyRight > p {
    display: inline-block;
    margin-top: 0;
}

.totalBottom {
    padding-left:16px;
    padding-right:16px;
    bottom: 0;
    margin-bottom: 20px;
    width: 92%;
}

.totalBottom .total {
    text-align: right;
}

.totalText {
    margin-bottom:0px;
    font-size: 14px;
    color: #8b8b8b;
    font-weight: 500;
}

.totalSum {
    margin-top:4px;
    font-weight: 600;
}

.CheckoutButton {
    width: 100%;
    height: 42px;
    border-radius: 7px;
    border: none;
    background-color: #05BD97;
    color: white;
    text-decoration: none;
    font-family: "SF Pro Text";
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    line-height: 25px;
    align-items: center;
    text-align: center;
}

.CheckoutButton:disabled {
    opacity: 0.7;
}

tbody tr {
    height:110px;
}

.spinnerContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -75px;
    position: relative;
}