.empty_archive_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 150px);
    min-height: 350px;
}

.empty_archive_container .image_wrapper {
    width: 220px;
    height: 227px;
    position: relative;
}

.empty_archive_container .image_wrapper .bg_image {
    background-image: url("./../images/bg_icon.svg");
    background-repeat: no-repeat;
    height: 227px;
    transform: rotate(-120deg);
}

.empty_archive_container.custom_theme .image_wrapper .bg_image {
    mask-image: url("./../images/bg_icon.svg");
    mask-repeat: no-repeat;
    background: #05BD97;
}

.empty_archive_container .image_wrapper .empty_archive_img {
    position: absolute;
    top: 70px;
    left: 60px;
}

.empty_archive_container .text_wrapper {
    /* margin-top: 20px; */
    max-width: 316px;
}

.empty_archive_container .text_wrapper > h1 {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.374px;
    color: #8E8E93;
}

.empty_archive_container .text_wrapper .subtitle {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.374px;
    color: #8E8E93;
}