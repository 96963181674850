.subcategories_container {
    padding: 67px 0 90px;
}

.subcategories_container .subcategories_header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
    max-height: 47px;
    height: 47px;
    padding: 10px;
    background: rgba(249, 249, 249, 0.7);
    box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(70px);
    color: #05BD97;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}


@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .subcategories_container .subcategories_header {
      background: rgba(249, 249, 249, 0.95);
    }
}

.subcategories_container .subcategoriesTitle {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #000000;
    margin: 0;
    padding: 28px 16px 0;
}

.subcategories_container .subcategoriesWrapper {
    display: grid;
    grid-template-columns: calc(50% - 8px) calc(50% - 8px);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    padding: 28px 16px 30px;
}

.subcategories_container .subcategoriesWrapper .subcategories_loader {
    box-shadow: 4px 4px 42px rgb(0 0 0 / 8%);
    border-radius: 10px;
    background-image: url("../../../spinner/loader.gif");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    height: 50px;
    grid-column-end: 3;
    grid-column-start: 1;
}

.subcategories_container .subcategoriesWrapper .subcategory_wrapper {
    background: #FFFFFF;
    box-shadow: 4px 4px 42px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 20px 10px 20px;
    text-decoration: none;
}

.subcategories_container .subcategoriesWrapper .subcategory_wrapper .subcategory_title {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    /* line-height: 41px; */
    letter-spacing: 0.374px;
    color: #000000;
    min-height: 47px;
    max-height: 47px;
    overflow: hidden;
}

.subcategories_container .subcategoriesWrapper .subcategory_wrapper .subcategory_img_wrapper {
    min-height: 137px;
    max-height: 137px;
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.subcategories_container .subcategoriesWrapper .subcategory_wrapper .subcategory_img_wrapper .subcategory_img {
    max-width: 100%;
    max-height: 137px;
}

@media screen and (max-width: 350px) {
    .subcategories_container .subcategoriesWrapper {
        grid-template-columns: calc(100% - 2px);
    }
    .subcategories_container .subcategoriesWrapper .subcategories_loader {
        grid-column-end: 2;
        grid-column-start: 1;
    }
}