.tabBarContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
}

.tabBarContainer .bgContainer {
    display: grid;
    position: relative;
}

.tabBarContainer .bgContainer .shop_ammount {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 13px 23px 0;
    height: 95px;
    background: rgba(249, 249, 249, 0.7);
    backdrop-filter: blur(70px);
    border-radius: 0px;
    position: relative;
    top: 40px;
}

.tabBarContainer .bgContainer .shop_ammount .total_wrapper {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.374px;
    color: #000000;
    padding-top: 7px;
}

.tabBarContainer .bgContainer .shop_ammount .total_wrapper > span {
    display: block;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.374px;
    color: #000000;
}

.tabBarContainer .bgContainer .shop_ammount .save_wrapper {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #000000;
    padding-top: 7px;
}

.tabBarContainer .bgContainer .shop_ammount .save_wrapper > span {
    display: block;
    font-weight: 700;
    color: #05BD97;
}

.tabBarContainer .bgContainer .cartBg {
    background: #05BD97;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    width: 66px;
    height: 66px;
    border-radius: 50%;
    justify-self: center;
    position: absolute;
    bottom: 40px;
    border: 4px solid rgba(0, 0, 0, 0.2);
}

.tabBarContainer .bgContainer .cartBg .cartIcon {
    padding: 15px;
    position: relative;
    display: block;
}

.tabBarContainer .bgContainer .cartBg button.cartIcon {
    background: transparent;
    border: none;
    width: 100%;
}

.tabBarContainer .bgContainer .cartBg button.cartIcon:focus-visible {
    outline: none;
}

.tabBarContainer .bgContainer .cartBg .cartIcon > span:not(.number_of_items) {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.374px;
    color: #000000;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 22px;
    height: 22px;
    background: #FFFFFF;
    border-radius: 50%;
    border: 2px solid #05BD97;
    box-sizing: border-box;
}

.tabBarContainer .bgContainer .cartBg .cartIcon > span.number_of_items {
    background: #FFFFFF;
    border: 2px solid #05BD97;
    box-sizing: border-box;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #000000;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
}

.tabBarContainer .bgContainer .cartBg .cartIcon > span sup {
    line-height: 36px;
}

.tabBarContainer .bgContainer .iconsContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    background-image: url("./../img/tab_bar_bg.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    height: 60px;
    z-index: 1;
}

.tabBarContainer .bgContainer .iconsContainer .icon_wrapper {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tabBarContainer .bgContainer .iconsContainer .icon_wrapper.search {
    justify-self: flex-end;
}

.tabBarContainer .bgContainer .iconsContainer .icon_wrapper .categoryIcon,
.tabBarContainer .bgContainer .iconsContainer .icon_wrapper .searchIcon {
    background-repeat: no-repeat;
    background-position: center;
    height: 21px;
    width: 18px;
}

.tabBarContainer .bgContainer .iconsContainer .icon_wrapper .categoryIcon {
    background-image: url("./../img/category_icon.svg");
}

.tabBarContainer .bgContainer .iconsContainer .icon_wrapper .categoryIcon.active {
    mask-image: url("./../img/category_icon.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    background: #05BD97;
}

.tabBarContainer .bgContainer .iconsContainer .icon_wrapper .cartIcon {
    position: relative;
}

.tabBarContainer .bgContainer .iconsContainer .icon_wrapper .cartIcon > span {
    position: relative;
    top: -6px;
    right: -12px;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.374px;
    color: #000000;
    position: absolute;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border-radius: 50%;
    border: 2px solid #8e8e93;
    box-sizing: border-box;
}

.tabBarContainer .bgContainer .iconsContainer .icon_wrapper .cart_icon {
    width: 18px;
    height: 21px;
    mask-image: url("./../img/shopping_cart.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: cover;
    background: #8E8E93;
 }

.tabBarContainer .bgContainer .iconsContainer .icon_wrapper .cart_icon.active {
    background: #05BD97;
}

.tabBarContainer .bgContainer .iconsContainer .icon_wrapper .searchIcon {
    background-image: url("./../img/search_icon.svg");
}

.tabBarContainer .bgContainer .iconsContainer .icon_wrapper .searchIcon.active {
    mask-image: url("./../img/search_icon.svg");
    mask-repeat: no-repeat;
    mask-position: center;
    background: #05BD97;
}

.tabBarContainer .bgContainer .iconsContainer .icon_wrapper .label {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #8E8E93;
    padding-top: 5px;
}

.tabBarContainer .bgContainer .iconsContainer .icon_wrapper .label.active {
    color: #05BD97;
}

.tabBarContainer .bgContainer img {
    width: 100%;
}

@media screen and (orientation: landscape) {
    .tabBarContainer .bgContainer .cartBg {
        bottom: 10px;
    }
}

@media (min-width: 768px) and (orientation: portrait)  {
    .tabBarContainer .bgContainer .cartBg {
        width: 90px;
        height: 90px;
        bottom: 10px;
    }
}

@media screen and (max-width: 360px) {
    .tabBarContainer .bgContainer .cartBg {
        width: 60px;
        height: 60px;
        bottom: 45px;
    }
    .tabBarContainer .bgContainer .cartBg .cartIcon > span:not(.number_of_items) {
        right: 10px;
        top: 10px;
    }
    .tabBarContainer .bgContainer .cartBg .cartIcon > span.number_of_items {
        right: 6px;
        top: 4px;
    }
}