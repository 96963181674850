.category_container {
    padding: 0 0 90px;
}

.category_container .category-header {
    position: fixed;
    /* left: 0;
    right: 0;
    top: 0; */
    width: calc(100% - 20px);
    z-index: 9;
    max-height: 47px;
    height: 47px;
    padding: 10px;
    background: rgba(249, 249, 249, 0.7);
    box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(70px);
    color: #05BD97;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .category_container .category-header {
      background: rgba(249, 249, 249, 0.95);
    }
}

.category_container .body_wrapper {
    padding-top: 95px;
}

.category_container .categoriesTitle {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #000000;
    padding: 0 16px 0;
    margin: 0;
}

.category_container .productsTitle {
    padding: 10px 16px 0;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #000000;
    margin: 0;
}

/* SubCategories Style */

.category_container .sub_categories_container .title_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 28px 0;
}

.category_container .sub_categories_container .title_wrapper .title,
.category_container .sub_categories_container .title_wrapper .all_subcategories {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #000000;
    margin: 0;
}

.category_container .sub_categories_container .title_wrapper .title {
    margin-left: 16px;
}

.category_container .sub_categories_container .title_wrapper .all_subcategories {
    text-decoration: none;
    margin-right: 16px;
}

.category_container .sub_categories_container .sub_categories {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    padding: 0px 16px;
}

.category_container .sub_categories_container .sub_categories .subcategory_wrapper {
    background: #FFFFFF;
    box-shadow: 4px 4px 42px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    height: 203px;
    padding: 10px;
    flex: 0 1 auto;
    width: calc(50% - 28px);
}

.category_container .sub_categories_container .sub_categories .subcategory_wrapper .subcategory_title {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    /* line-height: 41px; */
    letter-spacing: 0.374px;
    color: #000000;
    min-height: 47px;
    max-height: 47px;
    overflow: hidden;
}

.category_container .sub_categories_container .sub_categories .subcategory_wrapper .subcategory_img_wrapper {
    min-height: 137px;
    max-height: 137px;
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.category_container .sub_categories_container .sub_categories .subcategory_wrapper .subcategory_img_wrapper > img {
    max-width: 100%;
    max-height: 137px;
}

@media screen and (max-width: 350px) {
    .category_container .sub_categories_container .sub_categories .subcategory_wrapper {
        width: 100%;
    }
}


/* Skeleton style */
.category_container .sub_categories_container .sub_categories .subcategory_wrapper .title_skeleton > span {
    width: 70%;
}

.category_container .sub_categories_container .sub_categories .subcategory_wrapper .image_skeleton {
    width: 100%;
}

.category_container .sub_categories_container .sub_categories .subcategory_wrapper .image_skeleton > span {
    height: 137px !important;
}
/* End SubCategories Style */

/* Search input style */
.category_container .search_box_container {
    position: relative;
    display: flex;
    margin: 17px auto 0;
    width: calc(100% - 32px);
}

.category_container .search_box_container .search_box {
    background: rgba(118, 118, 128, 0.12);
    border-radius: 10px;
    width: calc(100% - 32px);
    padding: 7px 15px 7px 30px;
    border: none;
    color: #000000;
    height: 22px;
}

.category_container .search_box_container .search_box:focus,
.category_container .search_box_container .search_box:focus-visible {
    outline: none;
}

.category_container .search_box_container .search_box::placeholder {
    color: rgba(0, 0, 0, 0.4);

}

.category_container .search_box_container .search_icon {
    position: absolute;
    left: 8px;
    top: 10px;
    color: #8E8E93;;
}
/* End Search input style */

.category_container .categoryWrapper {
    display: grid;
    grid-template-columns: calc(50% - 8px) calc(50% - 8px);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    padding: 28px 16px 100px;
}

.category_container .categoryWrapper .products_loader {
    box-shadow: 4px 4px 42px rgb(0 0 0 / 8%);
    border-radius: 10px;
    background-image: url("../../../spinner/loader.gif");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    height: 50px;
    grid-column-end: 3;
    grid-column-start: 1;
}

.category_container .categoryWrapper .categoryProductBox {
    background: #FFFFFF;
    box-shadow: 4px 4px 42px rgb(0 0 0 / 8%);
    border-radius: 10px;
}

.category_container .categoryWrapper .categoryProductBox .body_container {
    padding: 10px;
    position: relative;
}

.category_container .categoryWrapper .categoryProductBox .body_container .productLink {
    text-decoration: none;
}

.category_container .categoryWrapper .categoryProductBox .body_container .productCategoryTitle .vendor {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.374px;
    color: #8E8E93;
    margin: 0;
}

.category_container .categoryWrapper .categoryProductBox .body_container .productCategoryTitle > div {
    min-height: 42px;
    height: 42px;
    max-height: 42px;
    overflow: hidden;
}

.category_container .categoryWrapper .categoryProductBox .body_container .productCategoryTitle .title {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.374px;
    color: #000000;
}

.category_container .categoryWrapper .categoryProductBox .body_container .categoryImg {
    min-height: 150px;
    max-width: 165px;
    max-height: 150px;
    padding: 0 5px;
    margin: 15px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.category_container .categoryWrapper .categoryProductBox .body_container .categoryImg > img {
    max-width: 100%;
    max-height: 150px;
}

.category_container .categoryWrapper .categoryProductBox .body_container .categoryImg .on_sale_to {
    mask-image: url("../../../images/on-sale-bg.svg");
    position: absolute;
    left: 0px;
    bottom: 0px;
    mask-repeat: no-repeat;
    padding: 15px 44px 15px 10px;
    mask-position: center left;
    mask-size: 100%;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 16px;
    color: #FFFFFF;
    background-color: #05BD97;
}

.category_container .categoryWrapper .categoryProductBox .body_container .categoryImg .on_sale_to .date {
    font-size: 11px;
}

.category_container .categoryWrapper .categoryProductBox .categoryPriceContainer {
    position: relative;
    min-height: 36px;
}

.category_container .categoryWrapper .categoryProductBox .categoryPriceContainer .vendor-price-wrapper {
    position: relative;
    max-width: calc(100% - 75px);
    font-family: "SF Pro";
    width: fit-content;
    min-height: 36px;
}


.category_container .categoryWrapper .categoryProductBox .categoryPriceContainer .vendor-price-wrapper .discountPercentage {
    background-color: #FFE501;
    padding: 0 5px;
    font-family: SF Pro;
    font-style: normal;
    font-weight: 860;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.33px;
    color: #000000;
    width: 40px;
}

.category_container .categoryWrapper .categoryProductBox .categoryPriceContainer .vendor-price-wrapper .productPrice {
    background-color: #05BD97;
    font-family: "SF Pro";
    font-weight: 860;
    font-size: 17px;
    line-height: 36px;
    display: grid;
    grid-template-columns: calc(100% - 10px) 10px;
    align-items: center;
    letter-spacing: 0.33px;
    color: #FFFFFF;
    padding: 0 5px;
    border-bottom-left-radius: 10px;
    width: 40px;
    text-align: right;
}

.category_container .categoryWrapper .categoryProductBox .categoryPriceContainer .vendor-price-wrapper .productPrice > p {
    margin: 0;
}

.category_container .categoryWrapper .categoryProductBox .categoryPriceContainer .vendor-price-wrapper .productPrice span {
    font-family: "SF Pro";
    font-weight: 510;
    font-style: normal;
    font-size: 7px;
    line-height: 21px;
    display: flex;
    justify-content: center;
    letter-spacing: 0.33px;
    color: #FFFFFF;
    transform: rotate(-90deg);
    height: 15px;
}

.category_container .categoryWrapper .categoryProductBox .categoryPriceContainer .vendor-price-wrapper .comparePrice {
    background-color: #FEE600;
    align-items: center;
    padding: 2px;
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    right: -20px;
    bottom: 0px;
    width: 16px;
    height: 32px;
}

.category_container .categoryWrapper .categoryProductBox .categoryPriceContainer .vendor-price-wrapper .comparePrice p {
    font-family: "SF Pro";
    font-style: normal;
    font-weight: 510;
    font-size: 10px;
    line-height: 17px;
    letter-spacing: 0.33px;
    color: #000000;
    text-decoration-line: line-through;
    padding: 2px 0 0;
    margin: 0;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    height: 22px;
}

.category_container .categoryWrapper .categoryProductBox .categoryPriceContainer .vendor-price-wrapper .comparePrice span {
    font-family: "SF Pro";
    font-weight: 510;
    font-size: 7px;
    line-height: 10px;
    letter-spacing: 0.33px;
    color: #000000;
 }

.category_container .categoryWrapper .categoryProductBox .categoryPriceContainer .categoryCartButton {
    width: 40px;
    height: 40px;
    box-shadow: -4px -4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 16px 0px 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
}

.category_container .categoryWrapper .categoryProductBox .categoryPriceContainer .categoryCartButton.added {
    background: #05BD97;
}

.category_container .categoryWrapper .categoryProductBox .categoryPriceContainer .categoryCartButton .addButton {
    width: 100%;
    border-radius: 16px 0px 10px;
    border: none;
    background: transparent;
    padding: 0px 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.category_container .categoryWrapper .categoryProductBox .categoryPriceContainer .categoryCartButton .addButton > .plus_icon {
    mask-image: url("./../../../catalog//top_offers/assets/img/plus.svg");
    mask-repeat: no-repeat;
    mask-size: 30px;
    background-color: #05BD97;
    width: 30px;
    height: 30px;
}

.category_container .categoryWrapper .categoryProductBox .categoryPriceContainer .categoryCartButton.added .addButton > .checkmark_icon {
    mask-image: url("./../../../searchPage/assest/img/check_mark_icon.svg");
    mask-repeat: no-repeat;
    mask-size: 20px;
    background-color: #FFFFFF;
    width: 20px;
    height: 20px;
}

@media screen and (max-width: 350px) {
    .category_container .categoryWrapper {
        grid-template-columns: calc(100% - 2px);
    }
    .category_container .categoryWrapper .products_loader {
        grid-column-end: 2;
        grid-column-start: 1;
    }
}

@media screen and (min-width: 720px) {
    .category_container .categoryWrapper {
        grid-template-columns: repeat(3, calc(33.333% - 11px));
    }
}


/* Skeleton style */
.category_products_skeleton > span {
    width: 20%;
}

.category_image_skeleton {
    width: 100%;
}

.category_image_skeleton > span {
    height: 145px;
}

.category_title_skeleton {
    width: 50%;
}

.category_price_skeleton {
    position: relative;
    top: 16px;
}

.category_price_skeleton > span {
    width: 500px;
    max-width: 50%;
}

.category-toCart-skeleton-wrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 30%;
}

.category-toCart-skeleton-wrapper > span {
    height: 36px;
}