
.topOffer {
    position: relative;
    display: inline-grid;
    margin-top:30px;
    margin-left:19px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1), 0px 0px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    grid-template-columns: 250px;
    height: 392px;
    padding: 0 14px;
}

.topOffer:first-of-type {
    margin-left: 15px;
}

.topOffer:last-of-type {
    margin-right: 15px;
}

.topOffer .topOfferTitle {
    font-weight: 600;
    font-size: 15px;
    line-height: 41px;
    letter-spacing: 0.374px;
    color: #05BD97;
    text-transform: uppercase;
    font-family: "SF Pro Display";
}

.topOffer .productLink {
    text-decoration: none;
}

.topOffer .productTitle {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.374px;
    color: #000000;
    font-family: "SF Pro Display";
    max-height: 50px;
    overflow: hidden;
    height: 48px;
}

.topOffer .productImage {
    position: relative;
    padding: 0px 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200x;
    min-height: 200px;
    margin-top: 15px;
}

.topOffer .productImage > img {
    max-height: 200px;
    width: auto;
    max-width: 100%;
}

.topOffer .productImage .on_sale_to {
    mask-image: url("../../../../images/on-sale-bg.svg");
    position: absolute;
    left: -14px;
    bottom: 0;
    mask-repeat: no-repeat;
    padding: 22px 75px 25px 17px;
    mask-position: center left;
    mask-size: 100%;
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: #FFFFFF;
    background-color: #05BD97;
}

.topOffer .productImage .on_sale_to .date {
    font-size: 16px;
}

.topOffer .priceCartContainer {
    margin-left: -14px;
}

.topOffer .priceCartContainer .productPriceWrapper {
    display: inline-block;
    position: relative;
    margin-bottom: 26px;
    max-width: calc(100% - 75px);
    font-family: "SF Pro";
}

.topOffer .priceCartContainer .productPriceWrapper .discountPercentage {
    background-color: #FFE501;
    padding: 2px 5px;
    font-weight: 860;
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.374px;
    color: #000000;
    width: 70px;
    text-align: center;
}

.topOffer .priceCartContainer .productPriceWrapper .productPrice {
    background-color: #05BD97;
    font-weight: 860;
    font-size: 30px;
    line-height: 41px;
    display: grid;
    grid-template-columns: calc(100% - 15px) 15px;
    align-items: center;
    letter-spacing: 0.374px;
    color: #FFFFFF;
    padding: 0 5px;
    width: 70px;
    text-align: right;
    height: 41px;
}

.topOffer .priceCartContainer .productPriceWrapper .productPrice > p {
    margin: 0;
 }

.topOffer .priceCartContainer .productPriceWrapper .productPrice .currency {
    font-weight: 510;
    font-size: 14px;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.374px;
    color: #FFFFFF;
    transform: rotate(-90deg);
    position: relative;
    left: 2px;
}

.topOffer .priceCartContainer .productPriceWrapper .comparePrice {
    background-color: #FEE600;
    align-items: center;
    padding: 1px 5px 4px 5px;
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    right: -26px;
    bottom: 0px;
    width: 16px;
    height: 36px;
}

.topOffer .priceCartContainer .productPriceWrapper .comparePrice p {
    font-weight: 510;
    font-size: 11px;
    line-height: normal;
    letter-spacing: 0.374px;
    color: #000000;
    text-decoration-line: line-through;
    padding: 0;
    margin: 0;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    height: 22px;
    text-align: center;
}

.topOffer .priceCartContainer .productPriceWrapper .comparePrice span {
    font-weight: 510;
    font-size: 11px;
    line-height: normal;
    letter-spacing: 0.374px;
    color: #000000;
 }

 .topOffer .priceCartContainer .cartButton {
    width: 70px;
    height: 70px;
    box-shadow: -4px -4px 24px rgba(0, 0, 0, 0.08);
    border-radius: 16px 0px 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    transition: all 1s ease-in-out 0s;
 }

 .topOffer .priceCartContainer .cartButton.added {
    background: #05BD97;
 }

 .topOffer .priceCartContainer .cartButton .addCartButton > .plus_icon {
     width: 40px;
     mask-image: url("../img/plus.svg");
     mask-repeat: no-repeat;
     mask-size: 40px;
     height: 40px;
     background-color: #05BD97;
 }

 .topOffer .priceCartContainer .cartButton.added .addCartButton > .checkmark_icon {
    width: 30px;
    mask-image: url("../../../../searchPage/assest/img/check_mark_icon.svg");
    mask-repeat: no-repeat;
    mask-size: 30px;
    height: 30px;
    background-color: #FFFFFF;
 }

 .topOffer .priceCartContainer .cartButton .addCartButton {
     background: transparent;
     border: none;
 }

 /* Skeleton style */
 .topOfer-skeleton > span {
     width: 50%;
 }

.title-skeleton > span {
    width: 70%;
}

.image-skeleton {
    width: 100%;
}

.toCart-skeleton-wrapper {
    width: 20%;
    float: right;
}