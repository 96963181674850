/* .shoppingCartContainer {
    padding-top: 69px;
} */

.shoppingCartContainer .shoppingCartHeader {
    position: fixed;
    /* left: 0;
    right: 0;
    top: 0; */
    width: calc(100% - 23px);
    z-index: 9;
    max-height: 47px;
    height: 47px;
    padding: 10px 13px 10px 9px;
    background: rgba(249, 249, 249, 0.7);
    box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(70px);
    color: #05BD97;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.shoppingCartContainer .shoppingCartHeader .edit_products {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #05BD97;
}

.shoppingCartContainer .shoppingCartHeader .edit_products .cancel {
    margin-right: 15px;
}

.shoppingCartContainer .shoppingCartHeader .complete_item_btn {
    width: 16px;
    height: 19px;
    -webkit-mask-image: url("../../../images/complete-items-btn-icon.svg");
    mask-image: url("../../../images/complete-items-btn-icon.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    background: #05BD97;
    margin-left: 15px;
    padding: 4px 0;
}

.shoppingCartContainer .shoppingCartHeader .complete_item_btn.disabled {
    background: #8E8E93 !important;
}

.shoppingCartContainer .shoppingCartHeader .archive_button {
 background: none;
 border: none;
 padding: 0;
 margin-left: 5px;
}

.shoppingCartContainer .shoppingCartHeader .archive_button .archive_icon {
    width: 21px;
    height: 21px;
    -webkit-mask-image: url("../../../images/archive-icon.svg");
    mask-image: url("../../../images/archive-icon.svg");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    background: #05BD97;
    padding: 2px;
}

.shoppingCartContainer .shoppingCartHeader .archive_button:disabled .archive_icon {
    background-color: #8E8E93 !important;
}

.shoppingCartContainer .shoppingCartHeader .cartTitle {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    /* line-height: 60px; */
    display: flex;
    align-items: center;
    letter-spacing: 0.374px;
    color: #000000;
    margin: 0;
    padding: 2px 0;
    width: 100%;
    justify-content: center;
    text-align: center;
}

.shoppingCartContainer .body_container {
    margin: 0 18px 120px;
    padding-top: 69px;
}

.shoppingCartContainer .body_container .cat_title {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.374px;
    color: #000000;
    padding-bottom: 16px;
}

.shoppingCartContainer .body_container .items_container {
    margin-top: 22px;
    margin-right: -18px;
}

.shoppingCartContainer .body_container .items_container .empty_cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vw + 150px);
    margin: 0 -18px;
}

.shoppingCartContainer .body_container .items_container .swipeable-list {
    margin-bottom: 16px;
}

.shoppingCartContainer .body_container .items_container .swipeable-list-item__content {
    display: block !important;
}

.shoppingCartContainer .body_container .items_container .swipeable-list-item__trailing-actions .removeIcon {
    display: flex;
    align-items: center;
    justify-content: center !important;
    background-color: red;
    padding: 15px 40px;
}

.shoppingCartContainer .body_container .items_container .item_container {
    display: grid;
    grid-template-columns: 75px calc(100% - 75px);
}

.shoppingCartContainer .body_container .items_container .item_container .checkbox_wrapper {
    display: flex;
    align-items: center;
}

.shoppingCartContainer .body_container .items_container .item_container .checkbox_wrapper > input {
    width: 22px;
    height: 22px;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.shoppingCartContainer .body_container .items_container .item_container .checkbox_wrapper > input:checked {
    background-color: var(--shop-color-var);
    background-image: url("../../../images/checkmark-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px;
}

.shoppingCartContainer .body_container .items_container .item_container.removing::after {
    content: "Removing...";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "SF Pro Display";
    color: #000000;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
}

.shoppingCartContainer .body_container .items_container .item_container .cartBodyLeft {
    max-width: 69px;
    min-width: 69px;
    max-height: 69px;
    min-height: 69px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #E7E7E6;
    box-sizing: border-box;
    border-radius: 14px;
    padding: 11px 4px;
    margin-right: 11px;
    margin-bottom: 10px;
    position: relative;
}

.shoppingCartContainer .body_container .items_container .item_container .cartBodyLeft > img {
    width: auto;
    max-height: 58px;
    max-width: 100%;
}

.shoppingCartContainer .body_container .items_container .item_container .cartBodyLeft > .item_quantity {
    background: #05BD97;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 11.44px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    position: absolute;
    top: 0px;
    left: 0px;
}

.shoppingCartContainer .body_container .items_container .item_container .item_info_wrapper {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
}

.shoppingCartContainer .body_container .items_container .item_container .item_info_wrapper .cartBodyMiddle .item_title {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-top: 8px;
    /* line-height: 25px; */
    color: #000000;
    min-height: 28px;
    max-height: 28px;
    max-width: calc(100% - 15px);
    overflow: hidden;
}

.shoppingCartContainer .body_container .items_container .item_container .item_info_wrapper .cartBodyMiddle.edit_mode .item_title {
    max-width: 100%;
}

.shoppingCartContainer .body_container .items_container .item_container .item_info_wrapper .cartBodyMiddle .price_container {
    display: flex;
    justify-content: space-between;
    padding-right: 15px;
}

.shoppingCartContainer .body_container .items_container .item_container .item_info_wrapper .cartBodyMiddle .price_container .item_compare_price {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    display: flex;
    align-items: center;
    text-decoration-line: line-through;
    color: #8E8E93;
}

.shoppingCartContainer .body_container .items_container .item_container .item_info_wrapper .cartBodyMiddle .price_container .item_price {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    display: flex;
    align-items: center;
    color: #05BD97;
}

.shoppingCartContainer .body_container .items_container .item_container .item_info_wrapper .cartBodyMiddle .price_container .cartBodyRight {
    display: flex;
    align-items: center;
}

.shoppingCartContainer .body_container .items_container .item_container .item_info_wrapper .cartBodyMiddle .price_container .cartBodyRight > span {
    padding: 0 10px;
}

.shoppingCartContainer .body_container .items_container .item_container .item_info_wrapper .cartBodyMiddle .price_container .cartBodyRight .btnDecrease,
.shoppingCartContainer .body_container .items_container .item_container .item_info_wrapper .cartBodyMiddle .price_container .cartBodyRight .btnIncrease {
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
}

.shoppingCartContainer .body_container .items_container .item_container .item_info_wrapper .cartBodyMiddle .price_container .cartBodyRight .btnIncrease {
    background-image: url("../../../images/plus-icon-white.svg");
    background-color: #05BD97;
}

.shoppingCartContainer .body_container .items_container .item_container .item_info_wrapper .cartBodyMiddle .price_container .cartBodyRight .btnDecrease {
    background-image: url("./../img//minus-icon.svg");
    background-color: #F6F4F6;
}

.shoppingCartContainer .complete_items_container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(249, 249, 249, 0.95);
    padding: 17px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.shoppingCartContainer .complete_items_container > button {
    background: transparent;
    border: none;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #05BD97;
}

.shoppingCartContainer .complete_items_container > button.cancel {
    color: #8E8E93 !important;
}

.shoppingCartContainer .complete_items_container > button:disabled {
    color: #8E8E93 !important;
    opacity: 0.5;
}

/* Shopping methods modal */
.shop_options_modal_wrapper  {
    width: 100% !important;
    height: auto;
    max-height: 90%;
    position: absolute;
    bottom: 0;
    margin-top: 0;
    background: #FFFFFF;
    border-radius: 24px 24px 0 0;
}

.shop_options_modal_wrapper > .slide-pane__header {
    display: none;
}

.shop_options_modal_wrapper > .slide-pane__content {
    padding: 0;
}

/* Confirm modal style */
#react-confirm-alert .react-confirm-alert-overlay {
 z-index: 99999;
}

#react-confirm-alert .react-confirm-alert .alert_custome_ui {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.078px;
    color: #000000;
    width: calc(70vw - 15px);
    max-width: 270px;
    padding: 15px;
    border-radius: 14px;
    background: #fff;
    box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
}

#react-confirm-alert .react-confirm-alert .alert_custome_ui > h1 {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.408px;
    color: #000000;
    margin: 0;
    padding-bottom: 5px;
}

#react-confirm-alert .react-confirm-alert .alert_custome_ui .button-group {
    border-top: 0.5px solid rgba(60, 60, 67, 0.36);
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 20px -15px -15px;
}

#react-confirm-alert .react-confirm-alert .alert_custome_ui .button-group > button {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.408px;
    color: #05BD97;
    background: transparent;
    margin: 0;
    border-radius: unset;
    justify-content: center;
    height: 44px;
    border: none;
}

#react-confirm-alert .react-confirm-alert .alert_custome_ui .button-group > button:first-of-type {
    border-right: 0.5px solid rgba(60, 60, 67, 0.36);
}


/* Skeleton style */
.shoppingCartContainer .body_container .items_container .item_container.skeleton .cartBodyLeft .item_image_skeleton {
    width: 100%;
}

.shoppingCartContainer .body_container .items_container .item_container.skeleton .cartBodyLeft .item_image_skeleton > span {
    height: 50px;
    border-radius: 5px;
}

.shoppingCartContainer .body_container .items_container .item_container.skeleton .cartBodyMiddle .item_title_skeleton > span {
    max-width: 80%;
}

.shoppingCartContainer .body_container .items_container .item_container.skeleton .cartBodyMiddle .item_price_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.shoppingCartContainer .body_container .items_container .item_container.skeleton .cartBodyMiddle .item_price_container .item_price_wrapper {
    width: 30%;
}

.shoppingCartContainer .body_container .items_container .item_container.skeleton .cartBodyMiddle .item_price_container .item_price_wrapper .item_compareAtPrice_skeleton,
.shoppingCartContainer .body_container .items_container .item_container.skeleton .cartBodyMiddle .item_price_container .item_price_wrapper .item_price_skeleton {
    width: 30%;
    height: 13px;
}

.shoppingCartContainer .body_container .items_container .item_container.skeleton .cartBodyMiddle .item_price_container .item_price_wrapper .item_compare_price {
    margin-bottom: 5px;
}

.shoppingCartContainer .body_container .items_container .item_container.skeleton .cartBodyMiddle .item_price_container .item_price_wrapper .item_compare_price,
.shoppingCartContainer .body_container .items_container .item_container.skeleton .cartBodyMiddle .item_price_container .item_price_wrapper .item_price,
.shoppingCartContainer .body_container .items_container .item_container.skeleton .cartBodyMiddle .item_price_container .item_price_wrapper .item_compareAtPrice_skeleton > span,
.shoppingCartContainer .body_container .items_container .item_container.skeleton .cartBodyMiddle .item_price_container .item_price_wrapper .item_price_skeleton > span {
    height: 13px;
}

.shoppingCartContainer .body_container .items_container .item_container.skeleton .cart_right_items_skeleton {
    margin-right: 15px;
    width: 60px;
    max-width: 30%;
}

.shoppingCartContainer .body_container .items_container .item_container.skeleton .cart_right_items_skeleton > span {
    height: 30px;
}

/* Helper style */
#___reactour .beJLEE {
    opacity: 0.4;
}

#___reactour #mask-main {
    display: none;
}

#___reactour .helper {
    width: 70%;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    background-image: url("./../../../images//tutorial-view-light.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    box-shadow: none;
    top: -48px;
    left: -7.5px;
    border-radius: 24px;
    padding: 24px 40px 10px 30px;
}

#___reactour .helper::after {
    content: "";
    position: absolute;
    right: -2px;
    top: 4.5px;
    -webkit-mask-image: url("./../../../images/share-icon.svg");
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: cover;
    mask-image: url("./../../../images/share-icon.svg");
    mask-repeat: no-repeat;
    mask-size: cover;
    width: 25px;
    height: 25px;
    background: #fff;
}

#___reactour .helper button {
    display: none;
}