.AppContainer{
  position:relative;
  width: 100%;
  min-height:100vh;
  overflow-y: auto;
  background: #FFFFFF;
}

.AppContainer .go_back {
  display: flex;
  align-items: center;
}

.AppContainer .go_back .angle_left_icon {
  mask-image: url("./components//images/angle-left-icon.svg");
  mask-repeat: no-repeat;
  width: 17px;
  height: 21px;
  background: #05BD97;
  
}

.AppContainer .go_back > span {
  color: #05BD97;
}

/* Reload cart alert */
.react-confirm-alert .reload_cart_custome_ui {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.078px;
  color: #000000;
  width: calc(70vw - 15px);
  max-width: 270px;
  padding: 15px;
  border-radius: 14px;
  background: #fff;
  box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
}

.react-confirm-alert .reload_cart_custome_ui .button-group {
  border-top: 0.5px solid rgba(60, 60, 67, 0.36);
  display: grid;
  margin: 20px -15px -15px;
}

.react-confirm-alert .reload_cart_custome_ui .button-group > button {
  font-family: "SF Pro Text";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.408px;
  color: #05BD97;
  background: transparent;
  margin: 0;
  border-radius: unset;
  justify-content: center;
  height: 44px;
  border: none;
}

.whiteBg::after {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 9999999;
}