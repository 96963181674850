.categories_container {
    padding: 66px 0 60px;
}

.categories_container.is_component {
    padding: 0;
}

.categories_container .categories_header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
    max-height: 47px;
    height: 47px;
    padding: 10px;
    background: rgba(249, 249, 249, 0.7);
    box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(70px);
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .categories_container .categories_header {
      background: rgba(249, 249, 249, 0.95);
    }
}

.categories_container > .categoryTitle {
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #000000;
    margin: 28px 16px;
}

.categories_container .categoriesWrapper {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 8px));
    grid-column-gap: 14px;
    grid-row-gap: 14px;
    padding: 0 10px 100px;
}

.categories_container.is_component .categoriesWrapper {
    padding: 30px 15px 50px;
}

.categories_container .categoriesWrapper .categories_loader {
    box-shadow: 4px 4px 42px rgb(0 0 0 / 8%);
    border-radius: 10px;
    background-image: url("../../../spinner/loader.gif");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    height: 50px;
    grid-column-end: 3;
    grid-column-start: 1;
}

@media screen and (max-width: 350px) {
    .categories_container .categoriesWrapper {
        grid-template-columns: calc(100% - 2px);
    }
    .categories_container .categoriesWrapper .categories_loader {
        grid-column-end: 2;
        grid-column-start: 1;
    }
}